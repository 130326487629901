import { QueueFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetQueues = gql`
  query getQueues($eventId: ID!) {
    queues(eventId: $eventId) {
      ...QueueFields
    }
  }
  ${QueueFields}
`
