import CONSTANTS from '@/constants'
import { GetCurrentUser } from '@/graphql/queries'
import { logWarning, writeToLocalStorage } from '@/util'
import { getVueInstance } from '@/vm'
import { onLogin, onLogout, restartWebsockets } from '@/vue-apollo'
import jwtDecode from 'jwt-decode'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

const { MS, KNOWN_ROLES: { SCOREKEEPER, STORE_ADMIN, WPN_ADMIN, STORE_OWNER } } = CONSTANTS

export const me = {
  lastAccessTime: null,
  sessionRefreshLoop: null,
  accessToken: '',
  platformProperties: null,
  isLoggedIn: false,
  displayName: null,
  hasValidEmail: false,
  isAuthorized: false,
  personaId: '',
  roles: []
}

function getCurrentUser () {
  return getVueInstance().$apollo
    .query({
      query: GetCurrentUser
    })
    .then(({ data: { me: { roles } } }) => {
      updateUserRoles(roles)
    })
    .catch(() => { })
}

function updateUserRoles (roles) {
  // The roles we get are readonly, make them so that we can modify
  // them should the need come
  me.roles = cloneDeep(roles)
  me.isAuthorized =
    Boolean(me.accessToken) &&
    (me.platformProperties['wotc-flgs'] & 1) === 1 &&
    me.roles.length > 0
  updateActivityTimeout() // when roles change, timeout might change
}

export function clearAppUser () {
  me.accessToken = null
  me.platformProperties = null
  me.isLoggedIn = false
  me.displayName = null
  me.hasValidEmail = false
  me.isAuthorized = false
  me.personaId = null
  me.roles = []
  if (me.activityTimeoutLoop) {
    clearInterval(me.activityTimeoutLoop)
    me.activityTimeoutLoop = null
  }
}

export function updateAppUser (data, clearAuthData) {
  const needsRestart = me.accessToken !== data.access_token
  if (clearAuthData) {
    clearAppUser()
  }
  me.accessToken = data.access_token
  me.platformProperties = jwtDecode(data.access_token)
  me.personaId = me.platformProperties.sub
  me.isLoggedIn = Boolean(data.access_token)
  me.displayName = me.platformProperties['wotc-name']
  me.hasValidEmail = (me.platformProperties['wotc-flgs'] & 1) === 1
  // If the access token changed, we must restart websockets
  if (needsRestart) {
    restartWebsockets()
  }
}

export function getStoresListFromRoles () {
  return me.roles
    .filter(({ roleName, organization }) => (roleName === STORE_ADMIN || roleName === SCOREKEEPER || roleName === STORE_OWNER) && organization !== null)
    .map(role => role.organization)
}

export function hasRoleWPNAdmin () {
  return Boolean(me.roles.find(({ roleName }) => roleName === WPN_ADMIN))
}

export function hasRoleStoreAdmin (organizationId) {
  return hasStoreRole(STORE_ADMIN, organizationId)
}

export function hasRoleStoreOwner (organizationId) {
  return hasStoreRole(STORE_OWNER, organizationId)
}

export function mayAdministrateStore (organizationId) {
  return hasRoleWPNAdmin() || hasRoleStoreAdmin(organizationId) || hasRoleStoreOwner(organizationId)
}

export function mayCreateEvents (organizationId) {
  return mayAdministrateStore(organizationId)
}

export function hasStoreAccess () {
  return Boolean(me.roles.find(({ roleName }) => [SCOREKEEPER, STORE_ADMIN, STORE_OWNER, WPN_ADMIN].includes(roleName)))
}

export function hasRoleScorekeeper (organizationId) {
  return hasStoreRole(SCOREKEEPER, organizationId)
}

export function hasStoreRole (role, organizationId) {
  // not all roles have an organization
  const orgRoles = me.roles.filter(({ organization }) => organization !== null)
  return Boolean(orgRoles.find(({ roleName, organization: { id } }) => roleName === role && organizationId === id))
}

export function login (apolloClient) {
  onLogin(apolloClient)
  window.SDK.auth.redirectToMyAccounts()
}

export function logout (apolloClient, redirect = false) {
  if (!me.isLoggedIn) {
    return
  }
  onLogout(apolloClient)
  stopSessionRefreshLoop()
  clearAppUser()
  window.SDK.auth.logOut()
  // Notify other tabs
  writeToLocalStorage(CONSTANTS.TAB_SYNC_EVENTS.LOGOUT, {
    time: new Date(),
    tabId: window.TAB_ID
  })
  if (redirect) {
    getVueInstance().$router.go()
  }
}

function sessionKeepAlive () {
  ensureLoggedIn(false, 'sessionKeepAlive', true)
}

function activityTimeout () {
  logWarning('Activity timeout is up, logging user out')
  logout(getVueInstance().$apollo.getClient(), true)
}

// TODO: should refactor all these args into an options bag
export function ensureLoggedIn (shouldGetCurrentUser = true, source = '', forceRefresh = false, notify = true) {
  return window.SDK.auth.refreshAuth(forceRefresh)
    .then(response => {
      if (me.accessToken !== response.access_token) {
        if (me.sessionRefreshLoop) {
          clearTimeout(me.sessionRefreshLoop)
        }
        // random number between 15 and 60
        const jitter = Math.floor(Math.random() * (60 - 15) + 15)
        const expiresIn =
            moment(response.next_refresh).diff(new Date()) - (jitter * 1000)
        // console.log('Expires in ', (expiresIn / 1000), ' including -', jitter, ' seconds of jitter')
        me.sessionRefreshLoop = setTimeout(sessionKeepAlive, expiresIn)

        // Notify other tabs
        if (notify) {
          writeToLocalStorage(CONSTANTS.TAB_SYNC_EVENTS.AUTH_REFRESHED, {
            time: new Date(),
            tabId: window.TAB_ID
          })
        }
      }
      if (shouldGetCurrentUser) {
        updateAppUser(response, true)
        return getCurrentUser()
      } else {
        updateAppUser(response, false)
      }
    })
    .catch(response => {
      logWarning(`Received error when ensuring logged in. Logging out. [${source}]`)
      logout(getVueInstance().$apollo.getClient(), true)
      throw response
    })
    .finally(() => {
      getVueInstance().$forceUpdate()
    })
}

// By default notifies other tabs of timeout
export function updateActivityTimeout (notify = true) {
  me.lastAccessTime = new Date()
  if (notify) {
    writeToLocalStorage(CONSTANTS.TAB_SYNC_EVENTS.ACTIVITY, {
      time: me.lastAccessTime,
      tabId: window.TAB_ID
    })
  }
  if (me.activityTimeoutLoop) {
    clearInterval(me.activityTimeoutLoop)
  }
  if (me.isLoggedIn) {
    const { WPN_ADMIN: adminTimeout, DEFAULT: defaultTimeout } = CONSTANTS.SESSION_TIMEOUT
    const timeout = hasRoleWPNAdmin() ? adminTimeout : defaultTimeout
    me.activityTimeoutLoop = window.setInterval(activityTimeout, timeout * MS)
  }
}

function stopSessionRefreshLoop () {
  if (me.sessionRefreshLoop) {
    clearInterval(me.sessionRefreshLoop)
    me.sessionRefreshLoop = null
  }
  clearAppUser()
}
