import { DraftFields } from '@/graphql/fragments/draftFields'
import { GameStateTeamFields } from '@/graphql/fragments/gameStateTeamFields'
import { RoundFields } from '@/graphql/fragments/roundFields'
import { SeatFields } from '@/graphql/fragments/seatFields'
import { StandingFields } from '@/graphql/fragments/standingFields'
import { gql } from '@apollo/client/core'

export const GameStateFields = gql`
  fragment GameStateFields on GameStateV2 {
    eventId
    minRounds
    podPairingType
    draft {
      ...DraftFields
    }
    playoffDraft {
      ...DraftFields
    }
    deckConstruction {
      timerId
      seats {
        ...SeatFields
      }
    }
    currentRoundNumber
    rounds {
      ...RoundFields
    }
    drops {
      teamId
      roundNumber
    }
    nextRoundMeta {
      hasDraft
      hasDeckConstruction
    }
    gamesToWin
    teams {
      ...GameStateTeamFields
    }
    playoffRounds
  }
  ${SeatFields}
  ${RoundFields}
  ${DraftFields}
  ${StandingFields}
  ${GameStateTeamFields}
`
