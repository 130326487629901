import { gql } from '@apollo/client/core'

export const GetIncident = gql`
  query getIncident($eventId: ID!, $id: ID!) {
    incident(eventId: $eventId,id: $id) {
      id
      ticketId
      reporter {
        personaId
        firstName
        lastName
      }
      offender {
        personaId
        firstName
        lastName
        displayName
      }
      infraction{
        id
        name
      }
      roundNumber
      penalty {
        id
        name
      }
      comment
      reportedAt
    }
  }
`
