import { OrganizationFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetOrganization = gql`
  query getOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationFields
    }
  }
  ${OrganizationFields}
`
