import { gql } from '@apollo/client/core'

export const DistributorsByGroups = gql`
  query distributorsByGroups($groupIds: [ID!]!) {
    distributorsByGroups(groupIds: $groupIds) {
      distributorId
      name
    }
  }
`
