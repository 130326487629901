import { DateTime } from 'luxon'

export type ValueOf<T> = T[keyof T]
export type KeyOf<T> = keyof T
export type Nullable<T> = T | null
export type Dictionary<T> = { [key: string]: T }

export interface InputFileEvent extends Event {
  target: HTMLInputElement;
}

export enum Colors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  CLEAR = 'clear',
  GREEN = 'green',
  PURPLE = 'purple',
  WOTC_PURPLE = 'wotc_purple',
  WHITE = 'white'
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
type VAR = `$${string}`;

export type ColorCode = RGB | RGBA | HEX | VAR;

export type HTMLInputTypeAttribute = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

// TODO: Migrating to ERS gql we will probably want to autogen this and a lot of the other types we get back from the backend
/**
 * A graphql "id"
 */
export type ID = number | string;

/**
 * Either an ISO formatted string or a luxon DateTime object
 */
export type OptionalDateTime = string | DateTime

export type SimpleMenuOption = { value: ID, text: string }
export type OptionalMenuItem = { value: string | null, text: string, disabled?: boolean }

export enum ScreenSize {
  EXTRA_LARGE = 'xl',
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  EXTRA_SMALL = 'xs'
}
