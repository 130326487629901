import { EventFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetEvent = gql`
  query getEvent($id: ID!, $locale: String) {
    event(id: $id) {
      ...EventFields
    }
  }
  ${EventFields}
`
