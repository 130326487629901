<template>
  <div id="app">
    <dev-console v-if="consoleAvailable" />
    <api-error
      id="global-api-errors"
      :show="showApiErrors"
      :api-errors="apiErrors"
      @dismissed="userHidErrors"
    />
    <router-view />
  </div>
</template>

<script>
import { getAppVar } from '@/appsettings'
import ApiError from '@/components/common/ApiError.vue'
import DevConsole from '@/components/common/DevConsole.vue'
import CONSTANTS from '@/constants'
import { ensureLoggedIn, me, updateActivityTimeout } from '@/models/auth.model'
import { useStatusStore } from '@/stores/status'
import md5 from 'md5'
import { defineComponent } from 'vue'

const STATUS_CHECK_TIME_IN_MS = 30000
const STATUS_MESSAGE_STORAGE_KEY = 'shownMessage'
const STATUS_MESSAGE_RESOLVE_STORAGE_KEY = 'shownResolveMessage'

export default defineComponent({
  name: 'App',
  components: { ApiError, DevConsole },
  setup () {
    const statusStore = useStatusStore()
    return { statusStore }
  },
  data () {
    return {
      apiErrors: [],
      showApiErrors: false,
      isPrerendered: window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered,
      statusTimer: null
    }
  },
  head () {
    return {
      title: '',
      titleTemplate: this.$t('page-title__eventlink') + ' %s'
    }
  },
  computed: {
    consoleAvailable () {
      const { hostname = null } = document.location
      return md5(hostname) === '49a6cb13dc7bd4e2d3c1253d0d5ef666'
    }
  },
  mounted () {
    this.$root.$on('apiError', this.handleApiError)

    window.addEventListener('storage', this.handleActivityFromTabs)
  },
  destroyed () {
    window.removeEventListener('storage', this.handleActivityFromTabs)
  },
  beforeDestroy () {
    clearInterval(this.statusTimer)
  },
  created () {
    this.setupStatusTimer()

    if (!this.isPrerendered) {
      const link = this.$router.resolve({ name: 'cookies' }).href
      const config = {
        cookieName: 'cookie-agree',
        cookieValue: 1,
        cookieExpiration: 60,
        acceptButtonLabel: this.$t('cookie-banner__accept'),
        nonConsentButtonLabel: this.$t('cookie-banner__decline'),
        copy: this.$t('cookie-banner__copy', { link }),
        targetMountElement: document.body
      }
      // eslint-disable-next-line no-undef
      window.cb = new CookieBanner(config)
      window.cb.consent.then(() => {
      }).catch((e) => {
        console.warn(e)
        if (window.cb.getConsentStatus === 'rejected') {
          this.dropCookies()
        }
      })
    }
  },
  methods: {
    setupStatusTimer () {
      // On first load do the check immediately
      this.checkStatus()
      console.log('Setting up Status check...')
      this.timeout = setInterval(this.checkStatus, STATUS_CHECK_TIME_IN_MS)
    },
    checkStatus () {
      this.$axios({
        method: 'GET',
        url: getAppVar('EMS_ENDPOINT') + `/status?lang=${this.$i18n.locale}`
      }).then(response => {
        if (response?.data) {
          const currentStatus = response.data.systemStatus

          if (currentStatus === 'Up') {
            // Nothing is wrong clear the current message if any
            this.statusStore.clearMessage()
          } else {
            const prevResolve = this.statusStore.isResolved

            // Current status is something else let's update the message
            this.statusStore.setMessage({ title: currentStatus.title, body: currentStatus.body, type: currentStatus.type, resolved: currentStatus.resolved ?? false })

            if (currentStatus.resolved && !prevResolve) {
              if (sessionStorage.getItem(STATUS_MESSAGE_RESOLVE_STORAGE_KEY) !== 'true') {
                this.$bvModal.show('acknowledgeModal')
                sessionStorage.setItem(STATUS_MESSAGE_RESOLVE_STORAGE_KEY, 'true')
              }
            } else if (sessionStorage.getItem(STATUS_MESSAGE_STORAGE_KEY) !== 'true' && this.statusStore.shouldShowBanner) {
              this.$bvModal.show('acknowledgeModal')
              sessionStorage.setItem(STATUS_MESSAGE_STORAGE_KEY, 'true')
            }
          }
        }
      })
    },
    async handleActivityFromTabs (e) {
      let data
      try {
        data = JSON.parse(e.newValue)
      } catch (e) {
        return // probably not something we're interested in
      }
      if (!data || !data.tabId || data.tabId === window.TAB_ID) {
        return
      }
      if (e.key === CONSTANTS.TAB_SYNC_EVENTS.ACTIVITY) {
        updateActivityTimeout(false)
      } else if (e.key === CONSTANTS.TAB_SYNC_EVENTS.LOGOUT) {
        this.$auth.logout(this.$apollo.getClient(), false, false)
        this.$router.go() // will reload the page if login status changed
      } else if (e.key === CONSTANTS.TAB_SYNC_EVENTS.AUTH_REFRESHED) {
        // were we logged in already?
        const wasLoggedIn = me.isLoggedIn
        await ensureLoggedIn(true, 'tabsync', false, false) // ensure logged in, but DON'T notify if it changed
        // are we logged in now?
        if ((!wasLoggedIn && me.isLoggedIn)) {
          this.$router.go() // will reload the page if login status changed
        }
      }
    },
    handleApiError (input) {
      if (typeof input === 'string') {
        this.apiErrors = [{ message: input }]
      } else {
        const { graphQLErrors = [] } = input
        this.apiErrors = graphQLErrors
      }
      this.showApiErrors = true
    },
    userHidErrors () {
      // hides global error banner until the next time an error occurs
      this.showApiErrors = false
      this.apiErrors = []
    },
    dropCookies () {
      setTimeout(() => {
        this.deleteAllCookies()
      }, 2000)
    },

    deleteAllCookies () {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const cookiename = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = cookiename + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    }
  }
})
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import 'node_modules/vue-tour/dist/vue-tour';
@import '@/scss/_reset.scss';
@import '@/scss/_buttons.scss';
@import '@/scss/_fonts.scss';
@import '@/scss/_forms.scss';
@import '@/scss/_themes.scss';
@import '@/scss/_animations.scss';
@import '@/scss/site.scss';
#app,
.layout,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
</style>
