import { acceptHMRUpdate, defineStore } from 'pinia'

export const useStatusStore = defineStore('status', {
  state: () => ({
    messageTitle: '',
    messageBody: '',
    messageType: '',
    isResolved: true
  }),
  getters: {
    shouldShowBanner (state) {
      return Boolean(state.messageTitle && state.messageBody) && !state.isResolved
    }
  },
  actions: {
    setMessage ({ title, body, type, resolved = false }) {
      this.messageTitle = title
      this.messageBody = body
      this.messageType = type
      this.isResolved = resolved
    },
    clearMessage () {
      this.messageTitle = ''
      this.messageBody = ''
      this.messageType = ''
      this.isResolved = true
    }
  }
})

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatusStore, import.meta.hot))
}
