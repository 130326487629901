import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-bridge'

import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import ja from './ja.json'
import ko from './ko.json'
import pt from './pt.json'
import ru from './ru.json'
import zhCn from './zh-CN.json'
import zhTw from './zh-TW.json'

// Set up internationalization
Vue.use(VueI18n)

export const messages = {
  de,
  en,
  es,
  fr,
  it,
  ja,
  ko,
  pt,
  ru,
  'zh-CN': zhCn,
  'zh-TW': zhTw
}

export function findBestLocale (preferredLocales) {
  const availableLocales = Object.keys(messages)

  for (const lang of preferredLocales) {
    if (availableLocales.includes(lang)) {
      return lang
    } else if (availableLocales.includes(lang.split('-')[0])) {
      return lang.split('-')[0]
    }
  }

  return 'en'
}

export const datetimeFormats = {
  en: {
    date: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    time: {
      hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    weekday: { weekday: 'long' },
    weekdayshort: { weekday: 'short' },
    month: { month: 'long' },
    day: { day: 'numeric' }
  },
  'ja-JP': {
    date: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    time: {
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    weekday: { weekday: 'long' },
    weekdayshort: { weekday: 'short' },
    month: { month: 'long' },
    day: { day: 'numeric' }
  }
}

export const i18n = createI18n({
  legacy: false,
  locale: findBestLocale(navigator.languages),
  fallbackLocale: 'en',
  messages,
  datetimeFormats,
  // Probably should move towards interpolation -> https://kazupon.github.io/vue-i18n/guide/interpolation.html
  warnHtmlMessage: false
}, VueI18n)

Vue.use(i18n)
