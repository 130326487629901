<template>
  <div class="store-brand-list">
    <StoreBrandInput
      v-for="(brand, idx) in brands"
      :key="brand.brandId"
      :brand="brand"
      :brand-options="availableBrandOptions"
      @removeBrand="emit('removeBrand', idx)"
    />
    <div
      class="store-brand-list__actions"
      :class="{ disabled: addDisabled, invalid }"
      @click="!addDisabled && emit('addBrand')"
    >
      <CircleExclamation
        v-if="invalid"
        :color="Colors.DANGER"
      />
      <CirclePlusIcon
        v-else
        purple
        large
      />

      <span class="store-brand-list__action-text">
        {{ $t('action__add-brand') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import StoreBrandInput from '@/components/common/form/StoreBrandInput.vue'
import CircleExclamation from '@/components/common/icons/CircleExclamation.vue'
import CirclePlusIcon from '@/components/common/icons/CirclePlusIcon.vue'
import { Colors } from '@/types'
import { SelectOption } from '@/types/forms'
import { Brand } from '@/types/organizations'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  brands: Array<Brand>
  brandOptions: Array<SelectOption>
}>()

const emit = defineEmits(['addBrand', 'removeBrand'])

const rules = {
  brands: {
    required,
    minLength: minLength(1)
  }
}
const v$ = useVuelidate(rules, props)
const addDisabled = computed(() => {
  return (v$.value.$invalid && props.brands.length !== 0) || props.brands.length >= (props.brandOptions.length - 1) // -1 because we don't care about the "Select a Brand" option
})

const invalid = computed(() => {
  return v$.value.$invalid && v$.value.$dirty && props.brands.length === 0
})

const availableBrandOptions = computed(() => {
  // Get array of brand ids that we have selected
  const selectedBrands = props.brands.map(brand => brand.brandId)

  // For each brand option we check if we have already selected them and if so disable them
  // Otherwise treat them as a valid option to choose from
  const options = props.brandOptions.map(brand => {
    return brand.value && selectedBrands.indexOf(brand.value) > -1
      ? {
          ...brand,
          disabled: true
        }
      : brand
  })

  return options
})
</script>

<style lang="scss">
.store-brand-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .store-brand-list__actions {
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &.invalid {
      color: $red;
      border: 1px solid $red;
      border-radius: 4px;

      .icon.icon--circle-plus circle,
      rect,
      path {
        stroke: $red;
      }
    }

    .icon.icon--circle-exclamation,
    .icon.icon--circle-plus {
      width: 30px;
      height: 30px;
    }
    .store-brand-list__action-text {
      margin: 0px 10px;
      font: $fontstyle-link;
      line-height: 16px;
    }
  }
}
</style>
