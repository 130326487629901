import { gql } from '@apollo/client/core'

export const TimerFields = gql`
  fragment TimerFields on Timer {
    id
    state
    durationMs
    durationStartTime
    serverTime
  }
`
