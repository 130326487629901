import { getAppVar } from '@/appsettings'
import { ensureLoggedIn, logout, me, updateActivityTimeout } from '@/models/auth.model'
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache'
import { ApolloClient, Observable, createHttpLink, from, split } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { getPlatformInfo, logWarning } from '../src/util'

// Install the vue plugin
Vue.use(VueApollo)

const SGS_PATH = getAppVar('SILVERBEAK_GRIFFIN_SERVICE')

// Vite will only allow custom variables that are prepended with `VITE`
// Http endpoint
const httpEndpoint = import.meta.env.MODE === 'test' ? 'http://testing.test/graphql' : SGS_PATH + '/graphql'
// Websocket endpoint
const wsEndpoint = import.meta.env.MODE === 'test' ? 'ws://testing.test/graphql' : SGS_PATH.replace('https', 'wss').replace('http:', 'ws:') + '/graphql'
/**
 * To better log and trace calls/subscriptions, a similar header is in companion
 * ex: client:eventlink version:local platform:Mac OS/chrome/112.0.0
 */
const tracingHeader = { 'X-wotc-client': `client:eventlink version:${getAppVar('SGW_VERSION')} platform:${getPlatformInfo()}` }

let apolloClient

const getAuth = (updateActivity = true) => {
  if (typeof window !== 'undefined') {
    if (updateActivity) {
      updateActivityTimeout()
    }
    return me.accessToken && me.accessToken.length > 0 && `Bearer ${me.accessToken}`
  }
}

/**
 * Will handle merging arrays based on a specific field that should be considered unique
 * This makes it's so when a type that uses this has an update to the cache we know what the resulting
 * array should look like
 *
 * @param {*} field The field that we want to determine uniqueness on
 * @returns A merged array that combines the existing and incoming values
 */
const mergeArrayByField = (field) => {
  return (existing, incoming, { readField, mergeObjects }) => {
    const curExisting = existing ? existing.slice(0) : []
    // Used to track determine the differences between `existing` and `incoming`
    const fieldToIndex = Object.create(null)
    if (existing) {
      existing.forEach((entry, index) => {
        fieldToIndex[readField(field, entry)] = index
      })
    }
    const merged = []
    incoming?.forEach(entry => {
      const incomingField = readField(field, entry)
      const index = fieldToIndex[incomingField]
      if (typeof index === 'number') {
        // Merge the new array data with the existing array data.
        merged[index] = mergeObjects(curExisting[index], entry)
      } else {
        // First time we've seen this entry in this array.
        fieldToIndex[incomingField] = merged.length
        merged.push(entry)
      }
    })
    return merged
  }
}

const cache = new InMemoryCache({
  dataIdFromObject (responseObject) {
    switch (responseObject.__typename) {
      case 'GameStateV2': return `GameStateV2:${responseObject.eventId}`
      default: return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Event: {
      fields: {
        registeredPlayers: {
          merge: mergeArrayByField('personaId')
        },
        incidents: {
          // I thought the id would already make this handled
          // but this is needed or warnings are shown about needing
          // a merge function for this array
          merge: mergeArrayByField('id')
        },
        teams: {
          merge: mergeArrayByField('id')
        }
      }
    },
    TeamPayload: {
      fields: {
        registrations: {
          merge: mergeArrayByField('personaId')
        },
        reservations: {
          merge: mergeArrayByField('personaId')
        }
      }
    },
    GameStateV2: {
      fields: {
        rounds: {
          merge: mergeArrayByField('roundNumber')
        },
        draft: {
          merge: true
        }
      }
    },
    DraftV2: {
      fields: {
        merge: mergeArrayByField('podNumber')
      }
    },
    Query: {
      fields: {
        queues: {
          merge: mergeArrayByField('queueId')
        }
      }
    }
  }
})

const noop = () => { }
// During tests this will fail because node doesn't have fetch, so use noop
const fetch = window.fetch || noop
const httpLink = createHttpLink({
  uri: httpEndpoint,
  fetch
})

const authLink = setContext(async (_, { headers }) => {
  const Authorization = getAuth()
  const authorizationHeader = Authorization ? { Authorization } : {}
  return {
    headers: {
      ...headers,
      ...authorizationHeader,
      ...tracingHeader
    }
  }
})

const wsClient = new SubscriptionClient(wsEndpoint, {
  reconnect: true,
  lazy: true,
  connectionParams: () => {
    const Authorization = getAuth(false)
    return Authorization ? { Authorization, ...tracingHeader } : {}
  }
})

// Create the subscription websocket link
const wsLink = new WebSocketLink(wsClient)

export const restartWebsockets = () => {
  // readyState 1 means open
  if (wsClient && wsClient.client && wsClient.client.readyState === 1) {
    wsClient.close(false, false) // because reconnectTrue is on, this will restart it
  }
}

const onErrorLink = onError(({ networkError, operation, forward }) => {
  // User access token has expired
  if (networkError && networkError.message === 'User is not logged in or does not have access') {
    // Let's refresh token through async request
    return new Observable(observer => {
      ensureLoggedIn(true, 'apollo').then(() => {
        restartWebsockets()
        operation.setContext(({ headers = {} }) => ({
          headers: {
            // Re-add old headers
            ...headers,
            ...tracingHeader,
            // Switch out old access token for new one
            authorization: getAuth(false)
          }
        }))
      })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          }

          // Retry last failed request
          forward(operation).subscribe(subscriber)
        })
        .catch(error => {
          logWarning('Received error when ensuring logged. Logging out. errorLinkHandler')
          logout()
          // No refresh or client token available, we force user to login
          observer.error(error)
        })
    })
  } else {
    // Let the default error handlers take over from here
    // Without this we intercept some errors from mutations and querys
  }
})

// Default at 2 minute timeouts to match previous functionality
const timeoutLink = new ApolloLinkTimeout(120000)

/* The link puts all the parts of our apollo client together:
  first: onErrorLink will attempt to refresh any bad tokens
  split: if the request is for WS, we use wsLink, for http, we use httpLink
  authLink.concat(httpLink) just adds auth headers to httpLink, wsLink handles this on its own
*/
const link = from([
  onErrorLink,
  split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' &&
        operation === 'subscription'
    },
    wsLink,
    authLink.concat(timeoutLink.concat(httpLink))
  )
])

// Call this in the Vue app file
export function createProvider () {
  apolloClient = new ApolloClient({
    link,
    cache
  })

  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        loadingKey: 'loading'
      }
    },
    errorHandler ({ message = null }) {
      // eslint-disable-next-line no-console
      console.error('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', message)
    }
  })

  return { apolloProvider, apolloClient }
}

// Manually call this when user log in
export async function onLogin (apolloClient) {
  restartWebsockets()
  try {
    if (apolloClient) {
      await apolloClient.clearStore()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  restartWebsockets()
  try {
    if (apolloClient) {
      await apolloClient.clearStore()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
