import { BaseValidation } from '@vuelidate/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n-bridge'

export interface FormElementProps {
  identifier?: string;
  fieldName: string;
  label?: string;
  validation?: BaseValidation;
  disabled?: boolean;
  isReadOnly?: boolean;
  required?: boolean;
  shortRequired?: boolean;
}

export function useFormComponent ({ validation = undefined, label = undefined, fieldName = '', required = false, shortRequired = false, identifier = undefined }: FormElementProps) {
  const { t } = useI18n()

  const invalid = computed(() => {
    return validation ? validation.$invalid && validation.$dirty : false
  })
  const labelName = computed(() => {
    return label ?? t(`label__${fieldName}`)
  })
  const fieldLabel = computed(() => {
    if (required) {
      return shortRequired ? `${labelName.value}*` : `${labelName.value}* (${t('label__required')})`
    }

    return labelName.value
  })
  const componentId = computed(() => {
    return identifier ?? `el-component__${fieldName}`
  })

  return { invalid, labelName, fieldLabel, componentId }
}
