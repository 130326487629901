let allowScroll = true
let alreadyScrolling = false
let restartScroll = false

function scrollToY (scrollTargetY, speed, element) {
  const scrollY = element.scrollHeight - element.clientHeight
  scrollTargetY = scrollTargetY || 0
  speed = speed || 100
  let currentTime = 0

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 10))
  let reverse = false

  if (!alreadyScrolling) {
    alreadyScrolling = true
  } else {
    allowScroll = false
    restartScroll = true
    return
  }

  // add animation loop
  function tick () {
    if (!allowScroll) {
      allowScroll = true

      if (restartScroll) {
        restartScroll = false
        alreadyScrolling = false
        scrollToY(scrollTargetY, speed, element)
      }

      return
    }

    currentTime += 1 / 60

    const p = currentTime / time
    const t = reverse ? p : -p + 1

    if (p < 1.2) {
      window.requestAnimFrame(tick)

      if (reverse) {
        element.scrollTo(0, scrollY + ((-scrollTargetY - scrollY) * t))
      } else {
        element.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
      }
    } else {
      currentTime = 0
      reverse = !reverse
      window.requestAnimFrame(tick)
    }
  }

  // call it once to get started
  tick()
}

export default {
  inserted (el, binding) {
    if (el) {
      const shouldScroll = el.scrollHeight > el.clientHeight
      if (shouldScroll) {
        allowScroll = true
        scrollToY(0, binding.value, el)
      } else {
        allowScroll = false
        alreadyScrolling = false
      }
    }
  },
  componentUpdated (el, binding) {
    if (el) {
      const shouldScroll = el.scrollHeight > el.clientHeight
      if (shouldScroll) {
        allowScroll = true
        scrollToY(0, binding.value, el)
      } else {
        allowScroll = false
        alreadyScrolling = false
      }
    }
  }
}
