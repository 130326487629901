<template>
  <div
    class="el-form-group el-checkbox"
    :class="{ invalid, disabled }"
  >
    <input
      :id="componentId"
      ref="input"
      :value="value"
      :disabled="disabled"
      :required="required"
      :checked="isChecked"
      type="checkbox"
      @change="onChange($event.target)"
    >
    <label :for="componentId">
      {{ fieldLabel }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { useFormComponent } from '@/composables/forms/useFormComponent'
import { Nullable } from '@/types'
import { BaseValidation } from '@vuelidate/core'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  identifier?: string;
  fieldName: string;
  label?: string;
  validation?: BaseValidation;
  disabled?: boolean;
  required?: boolean;
  shortRequired?: boolean;
  value: Nullable<string | boolean>;
  trueValue?: Nullable<string | number | boolean>;
  falseValue?: Nullable<string | number | boolean>;
}>(), {
  identifier: undefined,
  label: undefined,
  validation: undefined,
  value: '',
  required: false,
  shortRequired: false,
  trueValue: true,
  falseValue: false
})

const emit = defineEmits(['input'])
const onChange = (element: Nullable<EventTarget>) => {
  // Vue2 `v-model` seemingly only works off of `input`. Vue3 is more flexible
  emit('input', (element as HTMLInputElement).checked ? props.trueValue : props.falseValue)
}

const { invalid, fieldLabel, componentId } = useFormComponent(props)

const isChecked = computed(() => {
  return props.value === props.trueValue
})

</script>
