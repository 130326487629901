import { gql } from '@apollo/client/core'

export const GetOrgsByPartialName = gql`
  query getOrgsByPartialName ($partialName: String!) {
    organizations(partialName: $partialName) {
      id
      name
    }
  }
`
