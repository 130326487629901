import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core'
import { createHead } from '@unhead/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { Pinia } from 'pinia'
import Vue, { DefineComponent, provide } from 'vue'
import VueApollo from 'vue-apollo'
import VueI18n, { Path, Values } from 'vue-i18n'
import Router from 'vue-router'

let vue: Vue

export function initVue (i18n:VueI18n, router:Router, pinia:Pinia, apolloProvider: VueApollo, apolloClient: ApolloClient<NormalizedCacheObject>, component: DefineComponent) {
  const head = createHead()

  vue = new Vue({
    i18n,
    router,
    pinia,
    apolloProvider,
    setup () {
      provide(DefaultApolloClient, apolloClient)
    },
    // Throw to rollbar
    errorCaptured (err) {
      window.$rollbar?.error(err)
      throw err // rethrow
    },
    render: h => h(component),
    unhead: head
  })
  return vue
}

export function getVueInstance () {
  if (!vue) {
    throw Error('Vue is not initialized')
  }

  return vue
}

export function translate (key: Path, values?: Values) {
  return getVueInstance().$t(key, values)
}
export function emitRoot (event: string, ...args: unknown[]) {
  return getVueInstance().$root.$emit(event, args)
}
