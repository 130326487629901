import { gql } from '@apollo/client/core'

export const GetPenaltyFields = gql`
  query getPenaltyFields($locale: String!) {
    infractions(locale: $locale) {
      id
      name
      category {
        id
        name
        order
      }
      defaultPenalty {
        id
        name
        order
      }
    }
    penalties(locale: $locale) {
      id
      name
      order
    }
  }
`
