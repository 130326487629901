import { acceptHMRUpdate, defineStore } from 'pinia'

// FUTURE: Be able to have specific tasks that are marked as working/in progress
// These still drive the main bit of state `isWorking` but if you want specific actions only
// on specific things being worked on it might be cool 🤷‍♂️
export const useWorkingStore = defineStore('working', {
  state: () => ({
    isWorking: false
  }),
  actions: {
    startWork () {
      this.setWorking(true)
    },
    stopWork () {
      this.setWorking(false)
    },
    setWorking (working) {
      this.isWorking = working
    }
  }
})

// HMR Support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkingStore, import.meta.hot))
}
