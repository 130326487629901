import { EventFields, IncidentFields, PlayerListFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetFullEvent = gql`
  query event($id: ID!, $locale: String) {
    event(id: $id) {
      ...EventFields
      ...PlayerListFields
      incidents {
        ...IncidentFields
      }
    }
  }
  ${EventFields}
  ${IncidentFields}
  ${PlayerListFields}
`
