<template>
  <div class="store-brand-input">
    <div
      class="store-brand-input__remove"
      @click="removeBrand"
    >
      <CircleMinusIcon
        large
        color="red"
      />
    </div>

    <ElSearchSelect
      v-model="v$.brandId.$model"
      :validation="v$.brandId"
      :options="brandOptions"
      field-name="brand"
      required
    />
  </div>
</template>

<script setup lang="ts">
import ElSearchSelect from '@/components/common/form/ElSearchSelect.vue'
import CircleMinusIcon from '@/components/common/icons/CircleMinusIcon.vue'
import { SelectOption } from '@/types/forms'
import { Brand } from '@/types/organizations'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const props = defineProps<{
  brand: Brand
  brandOptions: Array<SelectOption>
}>()

const emit = defineEmits(['removeBrand'])

const rules = {
  brandId: {
    required
  }
}

const v$ = useVuelidate(rules, props.brand)

const removeBrand = () => {
  emit('removeBrand')
}
</script>

<style lang="scss">
.store-brand-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  >* {
    width: 100%;
  }

  .store-brand-input__remove {
    width: 20px;
    height: 20px;
    cursor: pointer;

    .icon.icon--circle-minus {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
