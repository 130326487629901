import { Directive, DirectiveBinding } from 'vue'

type ClickOutsideEvent = (event: MouseEvent | TouchEvent) => void

interface ClickOutsideHTMLElement extends HTMLElement {
    clickOutside: ClickOutsideEvent
}

export const ClickOutsideDirective : Directive<ClickOutsideHTMLElement, ClickOutsideEvent> = {
  bind (el: ClickOutsideHTMLElement, binding: DirectiveBinding<ClickOutsideEvent>) {
    el.clickOutside = (e) => {
      if (e.target instanceof Element) {
        if (!(this === e.target || el.contains(e.target))) {
          binding.value(e)
        }
      }
    }

    window.addEventListener('click', el.clickOutside)
  },

  unbind (el: ClickOutsideHTMLElement) {
    window.removeEventListener('click', el.clickOutside)
  }
}
