import { RegistrationFields } from '@/graphql/fragments/registrationFields'
import { TeamPayloadFields } from '@/graphql/fragments/teamPayloadFields'
import { gql } from '@apollo/client/core'

export const PlayerListFields = gql`
  fragment PlayerListFields on Event {
    registeredPlayers {
      ...RegistrationFields
    }
    interestedPlayers {
      personaId
      displayName
      firstName
      lastName
      emailAddress
    }
    teams {
      ...TeamPayloadFields
    }
  }
  ${TeamPayloadFields}
  ${RegistrationFields}
`
