import { EVENT_ROUTE_NAMES } from '@/routes/names'

const Registration = () => import('@/components/event/registration/Registration.vue')
const Drafting = () => import('@/components/event/drafting/Drafting.vue')
const DeckConstruction = () => import('@/components/event/DeckConstruction.vue')
const PlayerListOnly = () => import('@/components/event/PlayerListOnly.vue')
const Pairings = () => import('@/components/event/Pairings.vue')
const Standings = () => import('@/components/event/Standings.vue')
const Penalties = () => import('@/components/event/Penalties.vue')
const Round = () => import('@/components/event/Round.vue')
const Mirror = () => import('@/components/event/mirror/Mirror.vue')
const Queues = () => import('@/components/event/queued/QueueView.vue')
const QueueManagement = () => import('@/components/event/queued/QueueManagement.vue')
const Matches = () => import('@/components/event/queued/QueueMatches.vue')

export default [
  {
    path: 'registration',
    name: EVENT_ROUTE_NAMES.REGISTRATION,
    props: true,
    component: Registration
  },
  {
    path: 'drafting/:count?', // 'count' means, is this the first or second drafting phase (top 8)
    name: EVENT_ROUTE_NAMES.DRAFTING,
    props: true,
    component: Drafting
  },
  {
    path: 'construction/:count?', // 'count' means, is this the first or second construction phase (top 8)
    name: EVENT_ROUTE_NAMES.DECK_CONSTRUCTION,
    props: true,
    component: DeckConstruction
  },
  {
    path: 'players',
    name: EVENT_ROUTE_NAMES.PLAYER_LIST_ONLY,
    props: true,
    component: PlayerListOnly
  },
  {
    path: 'penalties',
    name: EVENT_ROUTE_NAMES.PENALTIES,
    props: true,
    component: Penalties
  },
  {
    path: 'mirror',
    component: Mirror,
    name: EVENT_ROUTE_NAMES.MIRROR
  },
  {
    path: 'rounds/:roundNumber',
    name: EVENT_ROUTE_NAMES.ROUND,
    component: Round,
    props: true,
    redirect: 'rounds/:roundNumber/pairings',
    children: [
      {
        path: 'pairings',
        name: EVENT_ROUTE_NAMES.PAIRINGS,
        props: true,
        component: Pairings
      },
      {
        path: 'standings',
        name: EVENT_ROUTE_NAMES.STANDINGS,
        props: true,
        component: Standings
      }
    ]
  },
  {
    path: 'queues',
    name: EVENT_ROUTE_NAMES.QUEUES,
    component: Queues,
    props: true,
    redirect: 'queues/manager',
    children: [
      {
        path: 'manager',
        name: EVENT_ROUTE_NAMES.QUEUE_MANAGER,
        component: QueueManagement,
        props: true
      },
      {
        path: 'matches',
        name: EVENT_ROUTE_NAMES.MATCHES,
        component: Matches
      }
    ]
  }
]
