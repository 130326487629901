import CONSTANTS from '@/constants'

/**
 * This file is intended to be deleted once we have a proper GQL schema pulldown
 * Until that time we need to update this as enums change on the backend to match
 */
export default {
  rulesEnforcementLevels: [
    {
      name: 'CASUAL',
      description: 'rel__casual'
    },
    {
      name: 'REGULAR',
      description: 'rel__regular'
    },
    {
      name: 'COMPETITIVE',
      description: 'rel__competitive'
    },
    {
      name: 'PROFESSIONAL',
      description: 'rel__professional'
    }
  ],
  pairingTypes: [
    {
      name: 'SWISS',
      description: 'pairing__swiss'
    },
    {
      name: 'SINGLE_ELIMINATION',
      description: 'pairing__single-elimination'
    },
    {
      name: 'PLAYER_LIST_ONLY',
      description: 'pairing__player-list-only'
    },
    {
      name: 'QUEUED',
      description: 'pairing__queued',
      featureFlag: CONSTANTS.FEATURES.COMMANDER_SUPPORT
    }
  ],
  phoneTypes: [
    { name: 'PRIMARY' },
    { name: 'BILLING' },
    { name: 'FAX' },
    { name: 'SHIPPING' },
    { name: 'STORE' }
  ],
  websiteTypes: [
    { name: 'PRIMARY' },
    { name: 'ECOMMERCE' },
    { name: 'STORE' }
  ],
  wpnStatuses: [
    { name: 'ACTIVE' },
    { name: 'TERMINATED' },
    { name: 'RETIRED' },
    { name: 'PENDING' }
  ],
  businessTypes: [
    { name: 'RETAIL' },
    { name: 'MASS_MARKET' },
    { name: 'VIRTUAL' },
    { name: 'DISTRIBUTOR' },
    { name: 'INDEPENDENT_ORGANIZER' }
  ],
  dbOperations: {
    INSERT: 'INSERT',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    TRUNCATE: 'TRUNCATE'
  }
}
