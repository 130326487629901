import { gql } from '@apollo/client/core'

export const FullUserSearch = gql`
  query userSearch($input: UserSearchInput!) {
    userSearch(input: $input) {
      page
      pageSize
      totalResults
      results {
        id
        personaId
        roleName
        emailAddress
        displayName
        firstName
        lastName

        organization {
          id
          name
          groups
          language
          status
          country
          state
          isPremium
          createdDate
          distributor {
            name
          }
        }
      }
    }
  }
`
