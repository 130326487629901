import { TimerFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetTimer = gql`
  query getTimer($id: ID!){
    timer(id:$id) {
      ...TimerFields
    }
  }
  ${TimerFields}
`
