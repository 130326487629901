import { EventFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetEventPage = gql`
  query getEventPage($organizationId: ID!, $filter: EventFilter, $locale: String) {
    eventPage(organizationId: $organizationId, filter: $filter) {
      events {
        ...EventFields
      }
      pageInfo {
        page
        pageSize
        totalResults
      }
      hasMoreResults
    }
  }
  ${EventFields}
`
