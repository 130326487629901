import { RoleFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetOrganizationForEdit = gql`
  query getOrganizationForEdit($id: ID!) {
    organizationFeatures(id: $id)
    organization(id: $id) {
      id
      name
      latitude
      longitude
      phoneNumber
      emailAddress
      website
      businessType
      showStoreInSEL
      showInInternalSearch
      isPremium
      legalName
      address
      address2
      city
      state
      country
      postalCode
      wpnRep
      websites {
        id
        url
        type
      }
      phoneNumbers {
        id
        countryCode
        phoneNumber
        type
        showInSEL
      }
      showEmailInSEL
      retailTeamEmail
      language
      notes
      status
      distributor {
        distributorId
        name
      }
      brands
      groups {
        id
        onlineOnly
        isRegion
      }
      ...RoleFields
    }
  }
  ${RoleFields}
`
