import { ensureLoggedIn, hasRoleWPNAdmin, updateActivityTimeout } from '@/models/auth.model'
import { checkTermsAndConditions } from '@/util'

export default {
  loginCheck ({ meta: { skipAuth = false } = {} }, _from, next) {
    if (skipAuth) {
      next()
    } else {
      return ensureLoggedIn(true, 'guard')
        .then(() => {
          updateActivityTimeout()
          next()
        })
        .catch(() => {
          next('/')
        })
    }
  },

  termsCheck ({ meta: { skipTerms = false } = {}, params: { storeId } }, _from, next) {
    if (storeId && !skipTerms && !hasRoleWPNAdmin()) {
      checkTermsAndConditions(storeId).then(agreed => {
        if (agreed) {
          next()
        } else {
          // cached org had accept: false, but maybe it changed on the server
          // you'd think apollo's cache-first policy would do this automatically,
          // but remember that it caches objects by ID, not individual fields of objects
          // so it won't automatically refetch an org that it has in the cache just
          // to see if the date field value changed
          checkTermsAndConditions(storeId, { fetchPolicy: 'network-only' }).then(agreed => {
            if (agreed) {
              next()
            } else {
              next({ name: 'terms', params: { storeId } })
            }
          })
        }
      })
    } else {
      next()
    }
  }
}
