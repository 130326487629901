<template>
  <div class="store-phone-input">
    <div
      v-if="!isFirstPhone"
      class="store-phone-input__remove"
      @click="$emit('removePhoneNumber')"
    >
      <circle-minus-icon
        large
        color="red"
      />
    </div>

    <ElInput
      v-model="v$.countryCode.$model"
      :validation="v$.countryCode"
      class="store-phone-input__country-code"
      field-name="country-code"
      required
      short-required
    />
    <ElInput
      v-model="v$.phoneNumber.$model"
      :validation="v$.phoneNumber"
      class="store-phone-input__phone-number"
      field-name="phone-number"
      required
    />
    <ElSearchSelect
      v-model="v$.type.$model"
      class="store-phone-input__phone-type"
      :validation="v$.type"
      :options="phoneTypes"
      field-name="phone-type"
      required
      :disabled="isFirstPhone"
    />
    <ElCheckbox
      v-model="v$.showInSEL.$model"
      class="store-phone-input__show-in-sel"
      :validation="v$.showInSEL"
      field-name="show-in-sel"
      :identifier="`show-phone-in-sel-${index}`"
    />
  </div>
</template>

<script setup lang="ts">
import ElCheckbox from '@/components/common/form/ElCheckbox.vue'
import ElInput from '@/components/common/form/ElInput.vue'
import ElSearchSelect from '@/components/common/form/ElSearchSelect.vue'
import CircleMinusIcon from '@/components/common/icons/CircleMinusIcon.vue'
import { SelectOption } from '@/types/forms'
import { Phone } from '@/types/wpn-types'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  phone: Phone
  index: number
  phoneTypes: Array<SelectOption>
}>()

defineEmits(['removePhoneNumber'])

const rules = {
  countryCode: {
    required,
    maxLength: maxLength(9)
  },
  phoneNumber: {
    required,
    maxLength: maxLength(32)
  },
  type: {
    required,
    maxLength: maxLength(16)
  },
  showInSEL: {}
}
const isFirstPhone = computed(() => props.index === 0)
const v$ = useVuelidate(rules, props.phone)
</script>

<style lang="scss">
.store-phone-input {
  width: 100%;

  &:first-child {
    grid-template-areas: 'code phone type sel';
    grid-template-columns: 20% repeat(3, minmax(0, 1fr));

    @include lg {
      grid-template-areas: 'code phone type'
                           '. . sel';
      grid-template-columns: 20% repeat(2, minmax(0, 1fr));
    }

    @include md {
      grid-template-areas: 'code phone' 'type sel';
      grid-template-columns: 40% minmax(0, 1fr);
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }

    @include sm {
      grid-template-columns: 30% minmax(0, 1fr);
    }
  }

  display: grid;
  grid-template-areas: 'remove code phone type sel';
  column-gap: 10px;
  grid-template-columns: 20px 20% repeat(3, minmax(0, 1fr));

  @include lg {
    row-gap: 10px;
    grid-template-areas: 'remove code phone type'
      '. . . sel';
    grid-template-columns: 20px 20% repeat(2, minmax(0, 1fr));
  }

  @include md {
    grid-template-areas: 'remove code phone' 'remove type sel';
    grid-template-columns: 20px 40% minmax(0, 1fr);
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @include sm {
    grid-template-columns: 20px 30% minmax(0, 1fr);
  }

  .store-phone-input__country-code {
    grid-area: code;
  }

  .store-phone-input__phone-number {
    grid-area: phone;
  }

  .store-phone-input__phone-type {
    grid-area: type;

    &.disabled {
      opacity: 70%;
    }
  }

  .store-phone-input__show-in-sel {
    grid-area: sel;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    div {
      width: 100%;
    }
  }

  .store-phone-input__remove {
    width: 20px;
    height: 100%;
    cursor: pointer;
    grid-area: remove;
    display: flex;
    align-items: center;

    .icon.icon--circle-minus {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
