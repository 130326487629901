import { gql } from '@apollo/client/core'

export const EventsInProgress = gql`
  query eventsInProgress($id: ID!) {
    eventsInProgress(organizationId: $id) {
      id
      title
      scheduledStartTime
      status
      hasTop8
      round
      minimumRounds
      pairingType
      playoffRounds
    }
  }
`
