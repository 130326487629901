<template>
  <div
    class="store-website-input"
  >
    <div
      v-if="!isFirstWebsite"
      class="store-website-input__remove"
      @click="$emit('removeWebsite')"
    >
      <circle-minus-icon
        large
        color="red"
      />
    </div>

    <ElInput
      v-model="v$.url.$model"
      :validation="v$.url"
      field-name="website"
      required
    />
    <ElSearchSelect
      v-model="v$.type.$model"
      class="store-website-input__type"
      :validation="v$.type"
      :options="websiteTypes"
      field-name="website-type"
      required
      :disabled="isFirstWebsite"
    />
  </div>
</template>

<script setup lang="ts">
import ElInput from '@/components/common/form/ElInput.vue'
import ElSearchSelect from '@/components/common/form/ElSearchSelect.vue'
import CircleMinusIcon from '@/components/common/icons/CircleMinusIcon.vue'
import { SelectOption } from '@/types/forms'
import { Website } from '@/types/wpn-types'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, url } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  website: Website
  index: number
  websiteTypes: Array<SelectOption>
}>()

defineEmits(['removeWebsite'])

const rules = {
  url: {
    required,
    url,
    maxLength: maxLength(256)
  },
  type: {
    required,
    maxLength: maxLength(16)
  }
}
const isFirstWebsite = computed(() => props.index === 0)
const v$ = useVuelidate(rules, props.website)
</script>

<style lang="scss">
.store-website-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  > * {
    width: 100%;
  }

  .store-website-input__remove {
    width: 20px;
    height: 20px;
    cursor: pointer;

    .icon.icon--circle-minus {
      width: 20px;
      height: 20px;
    }
  }

  .store-website-input__type {
    &.disabled {
      opacity: 70%;
    }
  }
}
</style>
