import { RegistrationFields } from '@/graphql/fragments/registrationFields'
import { gql } from '@apollo/client/core'

export const TeamPayloadFields = gql`
  fragment TeamPayloadFields on TeamPayload {
    id
    eventId
    teamCode
    isLocked
    isRegistered
    tableNumber
    reservations {
      personaId
      displayName
      firstName
      lastName
    }
    registrations {
      ...RegistrationFields
    }
  }
  ${RegistrationFields}
`
