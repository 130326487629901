<template>
  <div
    class="dev-console"
    :class="{ show }"
  >
    <p
      v-for="(value, property, index) in websocket"
      :key="index"
      class="message"
    >
      <span class="property">{{ property }}</span>
      <span class="value">{{ value }}</span>
    </p>
  </div>
</template>

<script>
// note: this "console" grants no special privileges, it's just
// a way to peek at things that are ordinarily hard to get to.
export default {
  name: 'DevConsole',
  data () {
    return {
      open: false,
      wsClient: {
        url: '',
        readyState: 0,
        bufferedAmount: 0
      },
      readyStates: ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED']
    }
  },
  computed: {
    show () { return this.open },
    url () { return this.wsClient.url },
    readyState () { return this.readyStates[this.wsClient.readyState] },
    bufferedAmount () { return this.wsClient.bufferedAmount },
    websocket () {
      return {
        url: this.url,
        readyState: this.readyState,
        bufferedAmount: this.bufferedAmount
      }
    }
  },
  created () {
    window.addEventListener('keyup', this.onKeyup)
  },
  destroyed () {
    window.removeEventListener('keyup', this.onKeyup)
  },
  methods: {
    onKeyup (e) {
      const { isTrusted = false, code = '', altKey = false } = e
      if (isTrusted && altKey && code === 'Backquote') {
        if (!this.open) {
          this.wsClient = this.$apollo.getClient().wsClient.client
        }
        this.open = !this.open
      }
    }
  }
}
</script>

<style lang="scss">
.dev-console {
  &.show { top: 0; }
  overflow: hidden;
  position: fixed;
  top: -100px;
  width: 100vw;
  background-color: #383e46;
  color: white;
  opacity: 0.8;
  font-family: monospace;
  padding: 10px;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75);
  transition: top 0.3s;
  z-index: 100;

  .message .property {
    color: yellow;
    font-weight: bold;
    &::after {
      content: ': ';
    }
  }
}
</style>
