import { gql } from '@apollo/client/core'

export const QueueFields = gql`
  fragment QueueFields on Queue {
    queueId
    name
    description
    groupSize
    isGlobal
    players
  }
`
