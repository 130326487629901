import { defaultStoreForm } from '@/components/wpn/WpnStoreForm.vue'
import CONSTANTS from '@/constants'
import { EventsInProgress, GetOrganization } from '@/graphql/queries'
import { findBestLocale } from '@/lang/config'
import { getVueInstance, translate } from '@/vm'
import { detect } from 'detect-browser'
import cloneDeep from 'lodash/cloneDeep'
import entries from 'lodash/entries'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import padStart from 'lodash/padStart'
import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'
import omitDeep from 'omit-deep'
import { calculateEventPlayoffRounds } from './helpers'

function pluralize (n, override) {
  const locale = override || findBestLocale(navigator.languages)
  const pr = new Intl.PluralRules(locale, { type: 'ordinal' })

  return translate(`label__place-suffix-${pr.select(n)}`, { n: n.toLocaleString(locale) })
}

/**
 * Convert a enum object to an array in the form that b-form-select expects
 * @param {enumType} obj An object whose keys are enum values and whose values are labels
 * @returns {Array} An array of { value, text } objects
 */
function enumToOptions (obj) {
  return sortBy(
    entries(obj).map(arr => ({ value: arr[0], text: arr[1] })),
    e => e.text
  )
}

/**
 * Convert a collection of objects to an array in the form that b-form-select
 * expects
 * @param {Array} arr An array of objects with 'id' and 'name' fields
 * @returns {Array} An array of { value, text } objects
 */
function collectionToOptions (arr) {
  return arr.map(({ id: value, name: text }) => ({ value, text }))
}

/**
 * Retrieve the time zone of the user's browser
 * @returns {string} An IANA time zone identifier
 */
function userTimeZone () {
  let tz
  try {
    tz = DateTime.local().zoneName
    if (tz === 'Asia/Calcutta') {
      tz = 'Asia/Kolkata'
    }
  } catch (_err) {
    tz = 'America/Los_Angeles'
  }
  return tz
}

function localeDate (datetime, { ifNull = CONSTANTS.MISSING_DATA } = {}) {
  return datetime ? datetime.toLocaleString(DateTime.DATE_SHORT) : ifNull
}

function localeTime (datetime, { ifNull = CONSTANTS.MISSING_DATA } = {}) {
  return datetime
    ? datetime.toLocaleString({
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short'
    })
    : ifNull
}

function localeTimehour (datetime, { ifNull = CONSTANTS.MISSING_DATA } = {}) {
  return datetime
    ? datetime.toLocaleString({
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
    : ifNull
}

function htmlDateValue (datetime) {
  return datetime && datetime.toISODate()
}

/**
 * the service gives us event times in UTC without a specified zone
 * e.g. "2019-09-09T17:00:00" and also the time zone we set
 * e.g. "America/Los_Angeles". This function returns a Luxon
 * DateTime object representing the instant in time from the
 * UTC timestamp, but in the event's time zone.
 * @param {String} time an ISO string representing the moment in time
 * @param {String} zone the IANA name of the time zone to use
 * @returns {Luxon.DateTime} a time-zone aware Luxon DateTime object
 */
function timeWithZone (time, zone = 'America/Los_Angeles') {
  if (!time) return null
  const datetime = DateTime.fromISO(time)
  if (!datetime.isValid) return null
  return datetime.setZone(zone)
}

/**
 * Decorate a GraphQL Event object with fields that are useful to the UI
 * @param {Object} gqlEvent An event received from the GraphQL service
 * @returns {Object} The event with its timestamp fields replaced by
 *   Luxon.DateTime objects, and with 'epoch' and 'dateTimeIndex' fields
 *   added. If there is gameState data included in the payload from the
 *   service, also patches the timestamp fields of the rounds and fills
 *   in the first and last names of players in the top8Pods.
 */
function hydratedEvent ({
  scheduledStartTime = null,
  actualStartTime = null,
  estimatedEndTime = null,
  actualEndTime = null,
  timeZone = null,
  ...rest
}) {
  const converted = {
    scheduledStartTime: timeWithZone(scheduledStartTime || new Date().toISOString(), timeZone),
    actualStartTime: timeWithZone(actualStartTime, timeZone),
    estimatedEndTime: timeWithZone(estimatedEndTime, timeZone),
    actualEndTime: timeWithZone(actualEndTime, timeZone),
    timeZone
  }
  const timeKeys = {
    epoch: converted.scheduledStartTime.toSeconds(),
    dateTimeIndex: converted.scheduledStartTime.toISODate()
  }
  return {
    ...converted,
    ...timeKeys,
    ...rest
  }
}

/**
 * Convert an event of the type returned by gqlEventToEvent into a valid
 * EventInput object. If the event contains an 'id' field, an UpdateEventInput
 * object will be returned; otherwise a CreateEventInput will be returned.
 * @param {Object} event An event with Luxon.DateTime-valued timestamp fields
 * @param {Object} options An object containing additional fields to add
 *   to the output object, intended for things like organizationId and
 *   lat/lng, which are not sourced from the event itself
 * @returns {Object} An object conforming to either the CreateEventInput or
 *   UpdateEventInput schema.
 */
function toEventInput ({
  title,
  eventFormat = {},
  eventFormatId = null, /* both because this might be a new event with no backing object */
  cardSet = {},
  cardSetId = null, /* both because this might be a new event with no backing object */
  rulesEnforcementLevel,
  pairingType,
  entryFee = {},
  entryFeeAmount: entryFeeAmountFromForm = null,
  entryFeeCurrency: entryFeeCurrencyFromForm = 'USD',
  venue = {},
  venueId: venueIdFromForm = null,
  capacity,
  description,
  timeZone = 'America/Los_Angeles',
  date = null,
  startTime = null,
  endTime = null,
  startingTableNumber = 1,
  hasTop8 = false,
  isAdHoc = false,
  isOnline = false,
  eventTemplateId = null,
  requiredTeamSize = 1
}, options = {}) {
  // don't spread the parameter's properties; it might be a Vue vm
  // e.g. EventEdit sends us "this"
  const entryFeeAmount = get(entryFee, 'amount', entryFeeAmountFromForm)
  const entryFeeCurrency = get(entryFee, 'currency', entryFeeCurrencyFromForm)
  const venueId = get(venue, 'id', venueIdFromForm)

  const startDateTime =
    date &&
    startTime &&
    DateTime.fromISO(`${date}T${startTime}`, { zone: timeZone })
  const endDateTime =
    date &&
    endTime &&
    DateTime.fromISO(`${date}T${endTime}`, { zone: timeZone })
  const scheduledStartTime = startDateTime && startDateTime.toISO()
  const estimatedEndTime = endDateTime && endDateTime.toISO()
  if (cardSet && cardSet.id) {
    cardSetId = cardSet.id
  }
  return {
    // This is only above so if we have an option that includes the eventTemplateId it can be overridden
    // Primarily this only happens with create since the eventTemplate object would be empty at that time
    eventTemplateId: eventTemplateId ?? undefined,
    title,
    eventFormatId: eventFormatId || eventFormat.id,
    cardSetId,
    rulesEnforcementLevel,
    pairingType,
    entryFeeAmount: parseInt(entryFeeAmount) ?? 0,
    entryFeeCurrency,
    venueId,
    capacity: capacity ? parseInt(capacity) : null,
    description,
    timeZone,
    scheduledStartTime,
    estimatedEndTime,
    startingTableNumber,
    hasTop8,
    isAdHoc,
    isOnline,
    requiredTeamSize,
    ...options
  }
}

function toRecurringEventInput (input, options = {}) {
  const {
    frequency = null,
    repeatUntil = null,
    dotWMask = 0,
    isDotWBound = true,
    startTime = null,
    timeZone = 'America/Los_Angeles'
  } = input

  const repeatUntilDateTime =
    repeatUntil &&
    startTime &&
    DateTime.fromISO(`${repeatUntil}T${startTime}`, { zone: timeZone })
  const repeatUntilTime = repeatUntilDateTime && repeatUntilDateTime.toISO()
  return {
    ...toEventInput(input, options),
    frequency,
    repeatUntil: repeatUntilTime,
    dotWMask,
    isDotWBound
  }
}

// checkTermsAndConditions with default cache-only policy consistently takes < 1 ms
function checkTermsAndConditions (storeId, { fetchPolicy = 'cache-only' } = {}) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    getVueInstance().$apollo
      .query({
        query: GetOrganization,
        variables: { id: storeId },
        fetchPolicy
      })
      .then(({ data }) => {
        const {
          organization: { acceptedTermsAndConditionsAt: agreed = false } = {}
        } = data
        // using lexical comparison here for speed
        resolve(agreed && agreed > CONSTANTS.LAST_TERMS_UPDATE)
      })
      .catch(err => reject(err))
  })
}

/**
 * This will search through a template looking for a specific field and if found will
 * return the rule that the field is set to. Only find the first rule so multiple rules
 * for one field is not accepted
 * @param {String} field - The field that you are searching for in the template
 * @param {Object} template - The template that you are searching through that contains field rules
 */
function getFieldRule (field, template) {
  if (!field || !template) {
    return false
  }

  if (template.fieldRules) {
    const comparison = kebabCase(field).toUpperCase()

    for (const rule of template.fieldRules) {
      if (kebabCase(rule.fieldName).toUpperCase() === comparison) {
        return rule.rule
      }
    }
  }

  return false
}

function getTemplate (templates, id) {
  return templates ? templates.find(o => o.id === id) : {}
}

function getTemplateDifferences (templates, oldId, newId) {
  if (!templates || !oldId || !newId) {
    return []
  }

  const templateOld = getTemplate(templates, oldId)
  const templateNew = getTemplate(templates, newId)

  if (templateOld && templateNew && templateOld.fieldRules && templateNew.fieldRules) {
    return templateNew.fieldRules.reduce((differences, newFieldRule) => {
      const matchedField = templateOld.fieldRules.find(o => o.fieldName === newFieldRule.fieldName)
      if (matchedField && matchedField.id !== newFieldRule.id) {
        differences.push(CONSTANTS.CONTENTFUL_FIELD_MAPPINGS[newFieldRule.fieldName])
      }
      return differences
    }, [])
  }

  return []
}

function scrollToTop () {
  window.scrollTo(0, 0)
}

function eventWithinAgeLimit (event) {
  // We are using ceil here because diff will return negative values
  return !event.actualEndTime ? true : Math.ceil(event.actualEndTime.diffNow().as('days')) >= -13
}

function timerObj (timeRemaining) {
  let timeMs = timeRemaining
  if (timeMs === undefined) return { minutes: '--', seconds: '--' }
  if (timeMs > CONSTANTS.MAX_TIMER) timeMs = CONSTANTS.MAX_TIMER
  else if (timeMs < CONSTANTS.MIN_TIMER) timeMs = CONSTANTS.MIN_TIMER
  // const sign = timeMs < 0 ? '-' : ''
  const minusCheck = timeMs < 0
  const time = Math.round(Math.abs(timeMs / 1000))
  const minutes = padStart(Math.floor(time / 60), 2, '0')
  const seconds = padStart(time % 60, 2, '0')
  return {
    minutes,
    seconds,
    minusCheck
  }
}
function timerDisplay (timeRemaining) {
  let timeMs = timeRemaining
  if (timeMs === undefined) return '--:--'
  if (timeMs > CONSTANTS.MAX_TIMER) timeMs = CONSTANTS.MAX_TIMER
  else if (timeMs < CONSTANTS.MIN_TIMER) timeMs = CONSTANTS.MIN_TIMER
  // const sign = timeMs < 0 ? '-' : ''
  const minusCheck = timeMs < 0
  const time = Math.round(Math.abs(timeMs / 1000))
  const minutes = padStart(Math.floor(time / 60), 2, '0')
  const seconds = padStart(Math.abs(time) % 60, 2, '0')
  return minusCheck ? `-${minutes}:${seconds}` : `${minutes}:${seconds}`
}
function paddingText (inputText, length, placeholder) {
  if (inputText < 0 && inputText >= -9) {
    inputText = Math.abs(inputText)
    return '-' + String(inputText).padStart(length, placeholder)
  } else {
    return String(inputText).padStart(length, placeholder)
  }
}

function getRecurrenceString ({ frequency, repeatUntil, dotWMask, isDotWBound, startDate }) {
  if (!frequency || frequency === 'SINGLE_EVENT') return ''

  if (frequency === 'DAILY') {
    return translate('label__daily-plain-text', { endDate: repeatUntil })
  } else if (frequency === 'WEEKLY') {
    return translate('label__weekly-plain-text', { selectedDays: selectedDaysString(dotWMask), endDate: repeatUntil })
  } else if (frequency === 'MONTHLY') {
    if (isDotWBound) {
      return translate('label__plain-text-monthly-weekday', {
        place: recurringWeek(startDate),
        weekday: recurringDay(startDate),
        endDate: repeatUntil
      })
    } else {
      return translate('label__plain-text-monthly-day', {
        day: recurringDate(startDate),
        endDate: repeatUntil
      })
    }
  } else {
    return ''
  }
}

function recurringDate (inDate) {
  const date = new Date(inDate)
  return pluralize(date.getUTCDate())
}

function recurringWeek (inDate) {
  const date = new Date(inDate)
  return pluralize(Math.min(Math.max(Math.ceil(date.getUTCDate() / 7), 1), 4))
}

function recurringDay (inDate) {
  const date = new Date(inDate)
  return [
    translate('label__sunday'),
    translate('label__monday'),
    translate('label__tuesday'),
    translate('label__wednesday'),
    translate('label__thursday'),
    translate('label__friday'),
    translate('label__saturday')
  ][date.getUTCDay()]
}

function selectedDaysString (dotWMask) {
  if (!dotWMask) return ''

  // Monday - Sunday
  const days = [2, 4, 8, 16, 32, 64, 1]
  const dayStrings = [
    translate('label__monday'),
    translate('label__tuesday'),
    translate('label__wednesday'),
    translate('label__thursday'),
    translate('label__friday'),
    translate('label__saturday'),
    translate('label__sunday')
  ]
  const daysSelected = []

  for (let i = 0; i < days.length; i++) {
    if (dotWMask & days[i]) {
      daysSelected.push(dayStrings[i])
    }
  }

  if (daysSelected.length === 1) return daysSelected[0]
  if (daysSelected.length === 2) return translate('label__list-item-two', [daysSelected[0], daysSelected[1]])

  let output = translate('label__list-item-end', ['{0}', daysSelected[daysSelected.length - 1]])

  // We already grab the last element
  let daysLeft = daysSelected.length - 1
  while (daysLeft >= 3) {
    daysLeft = daysLeft - 1
    const middle = translate('label__list-item-middle', ['{0}', daysSelected[daysLeft]])
    output = output.replace('{0}', middle)
  }
  output = output.replace('{0}', daysSelected[1])

  return translate('label__list-item-start', [daysSelected[0], output])
}

function getDateFromString ({ dateString, timeZone }) {
  return htmlDateValue(timeWithZone(dateString || new Date().toISOString(), timeZone))
}

/**
 * Grabs a localstorage item if it exists and the user has given consent.
 * @param {String} keyName the key we are checking if it exists in localstorage
 * @returns An object either with what exists in localstorage or null if no match was found
 */
function getLocalStorageItem (keyName) {
  // We want to make sure the user has consented else return null
  if (window.cb && window.cb.getConsentStatus !== 'resolved') return null
  // Apparently localStorage keys can come as the string version
  // We cover all the different options for falsey here that a simple
  // negate was not covering
  if (!localStorage ||
    !localStorage[keyName] ||
    localStorage[keyName] === 'null' ||
    localStorage[keyName] === 'undefined') return null

  return localStorage[keyName]
}

/**
 * Grabs a localstorage item if it exists and the user has given consent. This function is only for data saved in localstorage as JSON
 * @param {String} keyName the key we are checking if it exists in localstorage
 * @returns An object either with what exists in localstorage an empty object if no match was found
 */
function getLocalStorageJSON (keyName) {
  // We want to make sure the user has consented else return an empty object
  if (window.cb && window.cb.getConsentStatus !== 'resolved') return {}
  // Apparently localStorage keys can come as the string version
  // We cover all the different options for falsey here that a simple
  // negate was not covering
  if (!localStorage ||
    !localStorage[keyName] ||
    localStorage[keyName] === 'null' ||
    localStorage[keyName] === 'undefined') return {}

  try {
    return JSON.parse(localStorage[keyName])
  } catch (e) {
    // If we failed to parse also return an empty object because the localstorage item is most likely corrupt
    return {}
  }
}

function getUserPrefs () {
  return getLocalStorageJSON('preferences')
}

function updateUserPrefs ({ currency }) {
  // We want to make sure the user has consented else return early
  if (window.cb && window.cb.getConsentStatus !== 'resolved') return

  const prefs = getUserPrefs()

  if (prefs) {
    prefs.currency = currency
  }

  localStorage.preferences = JSON.stringify(prefs)
}

function getDraws (match) {
  if (match.isBye) return null
  if (match.results && match.results.length) {
    return match.results[0].draws || 0
  }
  return 0
}

// This is awful, but this is what Google place API gives us
const getAddressFromGooglePlaceComponents = (addressComponents) => {
  let streetNumber
  let route
  const address = {}
  for (let i = 0; i < addressComponents.length; i++) {
    if (addressComponents[i].types) {
      if (addressComponents[i].types.includes('route')) {
        route = addressComponents[i].short_name
      } else if (addressComponents[i].types.includes('street_number')) {
        streetNumber = addressComponents[i].short_name
      } else if (addressComponents[i].types.includes('locality')) {
        address.city = addressComponents[i].short_name
      } else if (addressComponents[i].types.includes('postal_code')) {
        address.postalCode = addressComponents[i].short_name
      } else if (addressComponents[i].types.includes('administrative_area_level_1')) {
        address.state = addressComponents[i].short_name
      } else if (addressComponents[i].types.includes('country')) {
        address.country = addressComponents[i].short_name
      } else {
        // Nothing happens if we don't find a relevant address component.
      }
    }
  }
  const streetNumberAndRoute = streetNumber ? `${streetNumber} ${route}` : route
  return {
    streetAddress: streetNumberAndRoute,
    ...address
  }
}

function sortAlphaNumeric (arr, locale, k) {
  if (!arr || !k || !locale) return []
  return arr.slice().sort((a, b) => {
    return get(a, k, '').toLowerCase().localeCompare(get(b, k, '').toLowerCase(), locale, {
      numeric: true
    })
  })
}

function logWarning (message) {
  console.warn(message)
  window.$rollbar?.info(message)
}

// Writes to local storage but swallows write errors, also stringifies non-strings to strings
function writeToLocalStorage (name, value) {
  const data = typeof value !== 'string' ? JSON.stringify(value) : value
  try {
    window.localStorage.setItem(name, data)
  } catch (e) {
    logWarning(e.message || 'could not write to local storage')
  }
}

function activePrereleases (templates) {
  if (!templates) return []

  const prereleases = []
  for (const template of templates) {
    if (template.templateType === CONSTANTS.TEMPLATE_TYPES.PRERELEASE && template.fieldRules) {
      // Look for the set to use for the id
      const setRule = template.fieldRules.find(rule => rule.fieldName === 'Set')
      const dateRule = template.fieldRules.find(rule => rule.fieldName === 'Date')

      // If a template cant pass the following then the template is not in a supported format
      if (setRule && dateRule && setRule.rule && dateRule.rule && dateRule.rule.value.range) {
        const matchIdx = prereleases.findIndex(el => el.setId === setRule.rule.value.id)
        const keyName = template.prereleaseType === CONSTANTS.PRERELEASE_TYPES.AT_HOME ? 'atHome' : template.prereleaseType === CONSTANTS.PRERELEASE_TYPES.IN_STORE ? 'inStore' : 'webcam'
        if (matchIdx > -1) {
          prereleases[matchIdx] = {
            ...prereleases[matchIdx],
            [keyName]: template.id
          }
        } else {
          prereleases.push({
            [keyName]: template.id,
            setId: setRule.rule.value.id,
            range: dateRule.rule.value.range
          })
        }
      }
    }
  }

  return prereleases
}

const teamName = (team) => {
  if (!team) return ''
  if (!team.registrations) return ''
  return team.registrations.map(p => playerName(p)).join(' / ')
}

const playerName = (player) => {
  if (!player) return ''
  if (!player.firstName && !player.lastName) return player.displayName
  return [player.firstName, player.lastName].filter(n => n).join(' ')
}

const triggerCSVDownload = (csvData, fileName) => {
  const file = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  // IE10+
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName)
    return
  }

  const a = document.createElement('a')
  const url = window.URL.createObjectURL(file)
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

const getCurrentRound = (gameState) => {
  if (!gameState) {
    return {}
  }
  return getRoundAt(gameState, gameState.currentRoundNumber)
}

const getRoundAt = ({
  rounds = []
}, roundNumber) => {
  if (!rounds) {
    return {}
  }
  // Sometimes we deal with numbers sometimes strings
  // Let's make sure we always are comaring against the same types
  return rounds.find(x => String(x.roundNumber) === String(roundNumber)) ?? {}
}

/**
 * Build a set of match result that you can send off to the server
 *
 * @param {Object} match The team id this result is for
 * @param {String} submitter The user who submitted these results (optional as the server should be able to work without it)
 * @param {Array<Number>} wins An array of wins to record that should match the number of teams in the match
 * @param {Number} draws Number of draws to record (default 0)
 * @returns A match result that can be sent off
 */
const buildMatchResults = (match, submitter, wins = [0, 0], draws = 0) => {
  if (!match) {
    return []
  }

  const { matchId, isBye, teams: [{ teamId: leftTeamId }, { teamId: rightTeamId = null } = {}] } = match
  if (match.isBye) {
    return [
      {
        matchId,
        isBye,
        teamId: leftTeamId,
        submitter,
        wins: 0,
        losses: 0,
        draws: 0
      }
    ]
  }

  return [
    {
      matchId,
      isBye,
      teamId: leftTeamId,
      submitter,
      wins: wins[0],
      losses: wins[1],
      draws
    },
    {
      matchId,
      isBye,
      teamId: rightTeamId,
      submitter,
      wins: wins[1],
      losses: wins[0],
      draws
    }
  ]
}

/**
 * Used to update an events cache entries. Primarily used for when Draft and Deck Coonstruction phases create rounds
 *
 * @param {*} eventId The event to update the status cache for
 * @param {*} status The new status of the event
 * @param {*} round The new current round number the event moved to
 * @param {*} minimumRounds The new minimum rounds that the event might have changed to
 */

const updateEventCacheStatus = (eventId, status, round, minimumRounds) => {
  const { cache } = getVueInstance().$apollo.getClient()
  cache.modify({
    id: `Event:${eventId}`,
    fields: {
      status () {
        return status
      }
    }
  })
  cache.modify({
    id: `InProgressEvent:${eventId}`,
    fields: {
      status () {
        return status
      },
      round () {
        return round
      },
      minimumRounds () {
        return minimumRounds
      }
    }
  })
}

const createEventCacheStatus = (event, storeId, status) => {
  const store = getVueInstance().$apollo.getClient()
  store.cache.modify({
    id: `Event:${event.id}`,
    fields: {
      status () {
        return status
      }
    }
  })

  const { eventsInProgress } = store.readQuery({
    query: EventsInProgress,
    variables: {
      id: storeId
    }
  })

  if (!eventsInProgress.some(e => e.id === event.id)) {
    // We need to create a new entry for this InProgress event
    const newInProgress = {
      __typename: 'InProgressEvent',
      id: event.id,
      status,
      scheduledStartTime: event.scheduledStartTime,
      hasTop8: event.hasTop8,
      title: event.title,
      round: 1,
      minimumRounds: 3,
      pairingType: event.pairingType,
      playoffRounds: calculateEventPlayoffRounds(event.hasTop8, event.eventFormat.includesDraft, event.requiredTeamSize, event.registeredPlayers, event.teams)
    }
    const clonedEvents = [...eventsInProgress, newInProgress]
    store.writeQuery({
      query: EventsInProgress,
      variables: {
        id: storeId
      },
      data: {
        eventsInProgress: clonedEvents
      }
    })
  } else {
    // If we already have a cache entry just update it
    store.cache.modify({
      id: `InProgressEvent:${event.id}`,
      fields: {
        status () {
          return status
        }
      }
    })
  }
}

const handleDrop = (currentDrops, drop, wasDropped = true) => {
  const clonedDrops = currentDrops ? [...currentDrops] : []
  // No use in performing logic if we have no drop to compare against
  if (!drop) {
    return clonedDrops
  }

  if (wasDropped) {
    if (clonedDrops.some(d => d.teamId === drop.teamId)) {
      return clonedDrops
    }

    return clonedDrops.concat(drop)
  } else {
    return clonedDrops.filter(d => d.teamId !== drop.teamId)
  }
}

function handleRoundsCacheUpdate (results, currentRounds, currentMatch) {
  const roundIdx = currentRounds.findIndex(x => x?.roundId === currentMatch?.roundId)
  if (roundIdx > -1) {
    const roundClone = cloneDeep(currentRounds[roundIdx])
    const matchIdx = roundClone.matches.findIndex(x => x.matchId === currentMatch.matchId)
    if (matchIdx > -1) {
      // Build new match that we are replacing the results for
      const newMatch = {
        ...roundClone.matches[matchIdx],
        results: results.map(x => { return { ...x, __typename: 'TeamResultV2' } })
      }
      // Overwrite the previous matches with our new one
      // Splice so we keep original match ordering in case anything is dependant on
      // current order of array
      roundClone.matches.splice(matchIdx, 1, newMatch)
      // Copy over the previous rounds that aren't the one we modified
      // And then add our newly modified round to it
      const currentRoundsCopy = [...currentRounds]
      currentRoundsCopy.splice(roundIdx, 1, roundClone)
      return currentRoundsCopy
    }
  }

  return currentRounds
}

function is2HGEvent (event) {
  return event.requiredTeamSize === 2
}

function getPlatformInfo () {
  // ex: Mac OS/chrome/112.0.0
  const browser = detect()
  return browser ? `${browser.os}/${browser.name}/${browser.version}` : 'Platform Not Detected'
}

function hydrateOrganizationForm (organization) {
  // We use omit to cleanup the `__typename` fields that are added to each object
  const {
    name,
    status,
    emailAddress,
    showEmailInSEL,
    roles = [],
    distributor,
    phoneNumber,
    phoneNumbers,
    website,
    websites,
    groups,
    brands = [],
    ...rest
    // Omit modifies the object so we want to make sure we aren't trying to modify the immutable one
  } = omitDeep(cloneDeep(organization), ['__typename'])

  const storeOwner = roles.find(role => role.roleName === CONSTANTS.KNOWN_ROLES.STORE_OWNER)

  // For all of the non-special handled keys revert to the expected
  // default value if they do not exist
  // This covers old stores that haven't updated to the new data yet
  for (const [key, value] of Object.entries(defaultStoreForm())) {
    if (!(key in rest)) {
      continue
    }

    // Any other falsy values we care about checking for?
    if (rest[key] === null || rest[key] === undefined) {
      rest[key] = value
    }
  }

  return {
    ...defaultStoreForm(),
    storeOwner: (storeOwner && storeOwner.user) || null,
    storeName: name ?? '',
    wpnStatus: status ?? null,
    storeEmail: emailAddress || '',
    showStoreEmailInSEL: showEmailInSEL ?? false,
    distributor: (distributor && distributor.distributorId) || null,
    groups: groups && groups.length
      ? groups.sort((a, b) => b.isRegion - a.isRegion)
      : [{ id: '' }],
    brands: brands.filter(Boolean).map(brand => ({ brandId: brand })),
    phoneNumbers: phoneNumbers && phoneNumbers.length
      ? phoneNumbers
      : [{
          countryCode: '1',
          phoneNumber: '',
          type: 'PRIMARY',
          showInSEL: false
        }],
    websites: websites && websites.length
      ? websites
      : [{
          url: '',
          type: 'PRIMARY'
        }],
    ...rest
  }
}

function arePropertiesEqual (obj, newObj, propertyKeys = []) {
  return propertyKeys.every(key => obj[key] === newObj[key])
}

export {
  activePrereleases, arePropertiesEqual, buildMatchResults, checkTermsAndConditions, collectionToOptions, createEventCacheStatus, enumToOptions, eventWithinAgeLimit, getAddressFromGooglePlaceComponents, getCurrentRound, getDateFromString, getDraws, getFieldRule, getLocalStorageItem, getLocalStorageJSON, getPlatformInfo, getRecurrenceString, getRoundAt, getTemplateDifferences, getUserPrefs, handleDrop,
  handleRoundsCacheUpdate, htmlDateValue, hydratedEvent, hydrateOrganizationForm, is2HGEvent, localeDate,
  localeTime, localeTimehour, logWarning, paddingText, playerName, pluralize, recurringDate, recurringDay, recurringWeek, scrollToTop, selectedDaysString, sortAlphaNumeric, teamName, timerDisplay,
  timerObj, timeWithZone, toEventInput,
  toRecurringEventInput, triggerCSVDownload, updateEventCacheStatus, updateUserPrefs, userTimeZone, writeToLocalStorage
}
