<template>
  <svg
    :width="large ? 47 : 15"
    :height="large ? 47 : 15"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon--circle-plus"
    :class="{ white, purple }"
  >
    <circle
      cx="23.5"
      cy="23.5"
      r="20.5"
      stroke="#6F59A5"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M23.5 10.9V36.1M10.9 23.5H36.1"
      stroke="#6F59A5"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  name: 'CirclePlusIcon',
  props: {
    white: {
      type: Boolean,
      default: false
    },
    purple: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.icon.icon--circle-plus {
  circle,
  rect,
  path {
    stroke: $electric-blue;
  }

  &.white {
    circle,
    rect,
    path {
      stroke: white;
    }
  }

  &.purple {
    circle,
    rect,
    path {
      stroke: $purple-admin;
    }
  }
}
</style>
