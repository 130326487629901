import {
  login,
  logout,
  me,
  ensureLoggedIn,
  hasRoleWPNAdmin,
  hasRoleStoreAdmin,
  hasRoleStoreOwner,
  hasRoleScorekeeper,
  mayAdministrateStore,
  mayCreateEvents,
  hasStoreAccess,
  getStoresListFromRoles
} from '@/models/auth.model'

export default {
  install (Vue) {
    Vue.prototype.$auth = {
      ensureLoggedIn,
      me,
      login,
      logout,
      hasRoleWPNAdmin,
      hasRoleStoreAdmin,
      hasRoleStoreOwner,
      hasRoleScorekeeper,
      mayAdministrateStore,
      mayCreateEvents,
      hasStoreAccess,
      getStoresListFromRoles
    }
  }
}
