import { EventFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetStoreEvents = gql`
  query getStoreEvents($filter: AdvancedEventFilter!, $locale: String) {
    storeEvents(filter: $filter) {
      events {
        ...EventFields
      }
      pageInfo {
        page
        pageSize
        totalResults
      }
      hasMoreResults
    }
  }
  ${EventFields}
`
