<template>
  <b-alert
    id="global-api-errors"
    :show="show"
    variant="danger"
    dismissible
    @dismissed="$emit('dismissed')"
  >
    <div v-html="$t('error__global-banner', { code })" />
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import uniq from 'lodash/uniq'

export default {
  name: 'ApiError',
  components: { BAlert },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    apiErrors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    code () {
      if (!this.apiErrors || !this.apiErrors.length) return ''
      return uniq(this.apiErrors.map(({ message }) => message)).sort().join('; ')
    }
  }
}
</script>

<style lang="scss">
#global-api-errors {
  font: $fontstyle-paragraph;
  position: fixed !important;
  margin: 0 !important;
  border-radius: 0 !important;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  line-height: 1.4;
  h4 {
    font: $fontstyle-heading4;
  }
  a {
    color: #721c24;
    text-decoration: underline;
  }
  .close {
    padding-top: 5px;
  }
  tt {
    font-family: monospace;
    background: #fafafa;
    padding: 2px 4px;
    line-height: 2;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}
</style>
