<template>
  <div>
    <v-select
      v-model="selectValue"
      :label="label ? label : undefined"
      :options="options"
      :clearable="false"
      :placeholder="placeholder"
      :reduce="(text) => (objectType ? text.value : text)"
      :searchable="searchable"
      :filter="filter || undefined"
      :selectable="option => option && !option.disabled"
      append-to-body
      :calculate-position="withPopper"
      :multiple="multiple"
    >
      <span slot="no-options">{{ $t('error__no-options-found') }}</span>
      <template #open-indicator="{ selectprop }">
        <span v-bind="selectprop">
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.91809 0L8.91809 6H0.918091L4.91809 0Z"
              :fill="state === false ? '#EB5757' : color"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.91809 16L0.918091 10H8.91809L4.91809 16Z"
              :fill="state === false ? '#EB5757' : color"
            />
          </svg>
        </span>
      </template>
      <template #search="{ attributes: attribs, events }">
        <input
          class="vs__search"
          :required="!selectValue"
          v-bind="attribs"
          v-on="events"
        >
      </template>
      <template
        v-if="objectType"
        #option="option"
      >
        <span v-if="useValueAsDisplay">{{ option.value }}</span>
        <span v-else>{{ option.text }}</span>
        <span
          v-if="option.badges"
          class="custom-select-badge-container"
        >
          <span
            v-for="(badge, idx) of option.badges"
            :key="idx"
            class="custom-select-badge"
            :class="badge.class"
          >
            {{ badge.name }}
          </span>
        </span>
      </template>
      <template
        v-if="objectType"
        #selected-option="option"
      >
        <span v-if="useValueAsDisplay">{{ option.value }}</span>
        <span v-else>{{ option.text }}</span>
        <span
          v-if="option.badges"
          class="custom-select-badge-container"
        >
          <span
            v-for="(badge, idx) of option.badges"
            :key="idx"
            class="custom-select-badge"
            :class="badge.class"
          >
            {{ badge.name }}
          </span>
        </span>
      </template>
      <!-- All of this is here just so that we can call stopPropagation on the deselect button -->
      <template
        v-if="multiple"
        #selected-option-container="{ option, deselect, multiple: isMultiple, disabled }"
      >
        <div class="vs__selected">
          <template v-if="objectType">
            <span v-if="useValueAsDisplay">{{ option.value }}</span>
            <span v-else>{{ option.text }}</span>
            <span
              v-if="option.badges"
              class="custom-select-badge-container"
            >
              <span
                v-for="(badge, idx) of option.badges"
                :key="idx"
                class="custom-select-badge"
                :class="badge.class"
              >
                {{ badge.name }}
              </span>
            </span>
          </template>
          <template v-else>
            {{ option.text }}
          </template>
          <button
            v-if="isMultiple"
            :disabled="disabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option.text}`"
            :aria-label="`Deselect ${option.text}`"
            @click.stop="deselect(option)"
            @mousedown.stop
          >
            <DeselectIcon />
          </button>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import DeselectIcon from './icons/DeselectIcon.vue'

export default {
  name: 'CustomSelect',
  components: { DeselectIcon },
  props: {
    options: {
      type: Array,
      required: true
    },
    objectType: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true,
      validator: prop => typeof prop === 'string' || typeof prop === 'number' || typeof prop === 'object' || prop === null
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Function,
      default: undefined
    },
    useValueAsDisplay: {
      type: Boolean
    },
    state: {
      type: Boolean,
      default: null
    },
    color: {
      type: String,
      default: '#5168e0'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    popupClass: {
      type: String,
      default: 'search-select'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      selectValue: this.value,
      attributes: {
        ref: 'openIndicator',
        role: 'presentation',
        class: 'vs__open-indicator'
      }
    }
  },
  watch: {
    selectValue (val) {
      this.$emit('input', val)
    },
    value (newVal) {
      if (!newVal !== this.selectValue) {
        this.selectValue = newVal
      }
    }
  },
  methods: {
    withPopper (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width
      dropdownList.classList.add(this.popupClass)

      const findTheme = (element) => {
        for (const cls of element.classList) {
          if (cls.includes('theme')) {
            return cls
          }
        }
        return element.parentElement ? findTheme(element.parentElement) : 'default'
      }

      const theme = findTheme(component.$el)

      if (theme !== 'default') {
        dropdownList.classList.add(theme)
      }

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.vs__selected-options {
  width: 100%;
  .vs__selected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .custom-select-badge-container {
      margin-left: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .custom-select-badge {
        background-color: $electric-blue;
        color: white;
        padding: 1px 4px;
        font-size: 10px;
        text-align: center;
        border-radius: 4px;
      }
    }
  }
}

.vs__dropdown-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 26px;

  span.custom-select-badge-container {
    .custom-select-badge {
      background-color: $electric-blue;
      color: white;
      margin: 2px;
      padding: 2px 4px;
      text-align: center;
      border-radius: 4px;
    }
  }
}

.vs__dropdown-toggle {
  border: 1px $electric-blue solid;
  width: 100%;
}
.vs__dropdown-menu {
  border: 1px $electric-blue solid;
  z-index: 1500;
  & li {
    &:hover,
    &:focus,
    &:active {
      background: none;
    }
  }
}
.vs__dropdown-option,
.vs__search,
.vs__selected {
  color: $electric-blue;
  font-size: 14px;
}
span.vs__selected {
  span {
    white-space: nowrap;
    overflow: hidden;
  }
}
.vs--open .vs__dropdown-toggle {
  border-color: $electric-blue;
  border-radius: 4px;
}
.vs__selected-options {
  flex-wrap: nowrap;
  & input {
    padding: 7px 10px 0 !important;
    min-height: 32px !important;
    font-size: 10px;
    font-weight: 300;
  }
}
.v-select.vs--single .vs__selected {
  padding: 12px 5px 0;
  margin-top: 0;
}
.v-select.vs--open .vs__selected {
  height: 100%;
}
.v-select .vs__dropdown-menu {
  max-height: 220px !important;
  overflow-y: scroll;
}
.vs__dropdown-option--highlight {
  background: $electric-blue !important;
}
.vs__dropdown-option--highlight {
  color: $white;
}
.vs__no-options {
  font-size: 14px;
  color: $electric-blue;
}
.vs__dropdown-option--disabled {
  opacity: 0.5;
}
</style>
