import { gql } from '@apollo/client/core'

export const OrganizationSearchFields = gql`
  fragment OrganizationSearchFields on SearchedOrganization {
    id
    name
    isPremium
    status
    language
    emailAddress
    phoneNumber
    wpnRep
    address
    address2
    city
    state
    postalCode
    country
    groups {
      name
    }
    regions {
      name
    }
    distributor {
      name
      distributorId
    }
    brands
  }
`
