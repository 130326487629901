import { RoleFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetRoles = gql`
  query getRoles($id: ID!) {
    organization(id: $id) {
      ...RoleFields
    }
  }
  ${RoleFields}
`
