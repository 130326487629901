import { RoleFields } from '@/graphql/fragments/roleFields'
import { gql } from '@apollo/client/core'

export const OrganizationFields = gql`
  fragment OrganizationFields on Organization {
    id
    name
    groups {
      id
      onlineOnly
    }
    latitude
    longitude
    phoneNumber
    emailAddress
    postalAddress
    isPremium
    acceptedTermsAndConditionsAt
    availableTemplates {
      id
      name
      startDate
      endDate
      isEvergreen
      hasPromoProduct
      featured
      templateQuota
      wpnArticle
      keyArt
      fieldRules {
        id
        fieldName
        rule {
          rule
          value
        }
      }
      templateType
      prereleaseType
      mustHaveWizardsAccount
    }
    venues {
      id
      name
      address
      timeZone
      phoneNumber
      googlePlaceId
      streetAddress
      city
      state
      country
      postalCode
      latitude
      longitude
      capacity
      emailAddress
      notes
      isApproved
      isDeleted
    }
    brands
    ...RoleFields
  }
  ${RoleFields}
`
