import { gql } from '@apollo/client/core'

export const ResultsFields = gql`
  fragment ResultsFields on TeamResultV2 {
    isBye
    wins
    losses
    draws
    teamId
  }
`
