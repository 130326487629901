import { gql } from '@apollo/client/core'

export const GetRecurrenceEvent = gql`
  query getRecurrenceEvent($organizationId: ID!, $eventId: ID!) {
    recurrenceEvent(organizationId: $organizationId, eventId: $eventId) {
      frequency
      repeatUntil
      dotWMask
      isDotWBound
    }
  }
`
