<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon--circle-minus"
  >
    <circle
      cx="10"
      cy="10"
      r="9.5"
      :stroke="iconStroke"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M4 10H16"
      :stroke="iconStroke"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  name: 'CircleMinusIcon',
  props: {
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconStroke () {
      switch (this.color) {
        case 'white':
          return '#FFFFFF' // $white
        case 'purple':
          return '#6F59A5' // $purple-admin
        case 'red':
          return '#EB5757' // $red
        case '':
          return '#5168e0' // $electric-blue
        default:
          return this.color
      }
    }
  }
}
</script>
