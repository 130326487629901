// a useful filter for displaying a user's name
export function fullName (user) {
  if (!user) return ''
  if (!user.firstName && !user.lastName) return user.displayName
  return [user.firstName, user.lastName].filter(n => n).join(' ')
}

// a useful filter for displaying a user's name
export function fullNameReverse (user) {
  if (!user) return ''
  if (!user.firstName && !user.lastName) return user.displayName
  return [user.lastName, user.firstName].filter(n => n).join(', ')
}

// a useful filter for displaying a user's display name
export function displayName (user) {
  if (!user) return ''
  return user.displayName
}

// a useful filter for displaying a user's first name
export function firstOnly (user) {
  if (!user) return ''
  return user.firstName || user.displayName
}

// a useful filter for displaying a user's last name
export function lastOnly (user) {
  if (!user) return ''
  return user.lastName || user.displayName
}

/**
 * Grab a teams name and fallback to the teams players reversed full names
 * if a team name is not set (LastName, FirstName)
 * @param {*} team The team to pull the name from
 */
export function teamNameReverse (team) {
  if (!team) return ''
  if (team.name) return team.name
  if (!team.players) return ''
  return team.players.map(p => fullNameReverse(p)).join(' / ')
}

/**
 * Grab a teams name and fallback to the teams players full names
 * if a team name is not set (FirstName, LastName)
 * @param {*} team The team to pull the name from
 */
export function teamName (team) {
  if (!team) return ''
  if (team.name) return team.name
  if (!team.players) return ''
  return team.players.map(p => fullName(p)).join(' / ')
}

/**
 * Grab a teams name and fallback to the teams players first names
 * if a team name is not set
 * @param {*} team The team to pull the name from
 */
export function teamNameFirstOnly (team) {
  if (!team) return ''
  if (team.name) return team.name
  if (!team.players) return ''
  return team.players.map(p => firstOnly(p)).join(' / ')
}

/**
 * Grab a teams name and fallback to the teams players last names
 * if a team name is not set
 * @param {*} team The team to pull the name from
 */
export function teamNameLastOnly (team) {
  if (!team) return ''
  if (team.name) return team.name
  if (!team.players) return ''
  return team.players.map(p => lastOnly(p)).join(' / ')
}

/**
 * Grab a teams name and fallback to the teams players display names
 * if a team name is not set
 * @param {*} team The team to pull the name from
 */
export function teamDisplayName (team) {
  if (!team) return ''
  if (team.name) return team.name
  if (!team.players) return ''
  return team.players.map(p => p.displayName).join(' / ')
}

// display float as a percentage
export function pct (f) {
  return `${(f * 100).toFixed(1)}%`
}

export function nextScreen ({
  status,
  pairingType,
  eventFormat: {
    includesDraft = false,
    includesDeckbuilding = false
  } = {}
}) {
  const duringSeating = ['DRAFTING', 'DECKCONSTRUCTION', 'ROUNDREADY'].includes(status)
  const duringPlay = ['ROUNDACTIVE', 'ROUNDCERTIFIED'].includes(status)

  if ((pairingType === 'PLAYER_LIST_ONLY') && (duringSeating || duringPlay)) {
    return { name: 'players' }
  }

  if (duringSeating) {
    if (includesDraft) return { name: 'drafting', params: { count: 1 } }
    if (includesDeckbuilding) return { name: 'construction' }
    return { name: 'pairings' }
  }

  if (duringPlay) return { name: 'pairings' }

  return { name: 'registration' }
}
