import { gql } from '@apollo/client/core'

export const FindUser = gql`
  query findUser($emailAddress: String!) {
    user(emailAddress: $emailAddress) {
      personaId
      firstName
      lastName
      displayName
    }
  }
`
