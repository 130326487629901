<template>
  <div
    class="el-form-group"
    :class="{ invalid: selectState, disabled }"
  >
    <label :for="componentId">
      {{ fieldLabel }}
    </label>
    <custom-select
      :id="componentId"
      :value="value"
      :options="options"
      :disabled="disabled"
      :class="selectStyle"
      :label="'text'"
      :filter="filter"
      :state="selectState"
      :position="position"
      :multiple="multiple"
      :placeholder="placeholder"
      class="el-search-select"
      popup-class="el-search-select"
      @input="(val) => onEvent('input', val)"
      @change="(val) => onEvent('change', val)"
    />
    <CircleExclamation
      v-if="selectState"
      :color="Colors.DANGER"
    />
  </div>
</template>

<script setup lang="ts">
import CustomSelect from '@/components/common/CustomSelect.vue'
import { useFormComponent } from '@/composables/forms/useFormComponent'
import { Colors, ID, Nullable } from '@/types'
import { SelectOption } from '@/types/forms'
import { BaseValidation } from '@vuelidate/core'
import { computed } from 'vue'
import CircleExclamation from '../icons/CircleExclamation.vue'

const props = withDefaults(defineProps<{
  identifier?: string;
  fieldName: string;
  label?: string;
  validation?: BaseValidation;
  disabled?: boolean;
  required?: boolean;
  shortRequired?: boolean;
  value: Nullable<string | number | ID[]>;
  styleDefault?: boolean;
  options: SelectOption[];
  position?: string;
  multiple?: boolean;
  placeholder?: string;
}>(), {
  identifier: undefined,
  label: undefined,
  validation: undefined,
  value: '',
  required: false,
  shortRequired: false,
  styleDefault: true,
  position: 'bottom',
  multiple: false,
  placeholder: ''
})

const emit = defineEmits(['input', 'change'])
const onEvent = (type: 'input' | 'change', value: string | number) => {
  emit(type, value)
}

const { fieldLabel, componentId } = useFormComponent(props)

const selectStyle = computed(() => {
  return props.styleDefault ? !props.value ? 'default-option-selected' : '' : ''
})

const filter = (options: SelectOption[], search: string) => {
  const lowerSearch = search.toLowerCase()
  return options.filter(option => {
    return option.text.toLowerCase().includes(lowerSearch) || String(option.value).toLowerCase().includes(lowerSearch)
  })
}

const selectState = computed(() => {
  return props.validation ? props.validation.$invalid && props.validation.$dirty : undefined
})
</script>

<style lang="scss">
.wz-custom-form-group {
  &.invalid-select {
    color: $red;
    label {
      color: $red;
    }
    .vs__dropdown-toggle {
      border-color: $red;
    }
    .vs__selected {
      color: $red;
    }
  }
}
</style>
