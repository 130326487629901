const Health = () => import('@/components/common/Health.vue')

export default [
  {
    path: '/health',
    component: Health,
    meta: {
      skipAuth: true,
      skipTerms: true
    }
  }
]
