import { IncidentFields } from '@/graphql/fragments/incidentFields'
import { gql } from '@apollo/client/core'

export const EventIncidentUpdate = gql`
  fragment EventIncidentUpdate on Event {
    id
    incidents {
      ...IncidentFields
    }
  }
  ${IncidentFields}
`
