import { gql } from '@apollo/client/core'

export const GetCurrentUser = gql`
  query getCurrentUser {
    me {
      displayName
      personaId
      displayName
      isEmailVerified
      roles {
        roleName
        organization {
          id
          name
          acceptedTermsAndConditionsAt
        }
      }
    }
  }
`
