import homeRoutes from './home'
import healthRoutes from './health'
import adminRoutes from './admin'
import storeRoutes from './store'
const Default = () => import('@/components/common/Default.vue')
const NotFound = () => import('@/components/common/NotFound.vue')
export default [
  ...homeRoutes,
  ...healthRoutes,
  // Default is used to know we have auth
  {
    path: '',
    component: Default,
    children: [
      ...adminRoutes,
      ...storeRoutes
    ]
  },
  { path: '*', component: NotFound }
]

/*
  index.js (this file)
    NotFound
    homeRoutes
      Landing
    healthRoutes
      Health
    default
      adminRoutes
        WpnList
      storeRoutes - these use StoreNavbar
        Store
          StoreSettings,
          TermsConditions,
          Calendar
          eventRoutes - these also use EventNavbar
            EventView (just using "Event" conflicts with browsers)
              Registration
              Seating
              DeckConstruction
              PlayerListOnly
              Penalties
                PenaltyCreate
                PenaltyEdit
              Round
                Pairings
                Standings
  */
