import eventRoutes from './event'
const EventView = () => import('@/components/event/EventView.vue')
const Store = () => import('@/components/store/Store.vue')
const TermsConditions = () => import('@/components/store/TermsConditions.vue')
const StoreDetail = () => import('@/components/store/StoreDetail.vue')
const Calendar = () => import('@/components/calendar/Calendar.vue')

export default [
  {
    path: '/stores/:storeId',
    props: true,
    component: Store,
    redirect: '/stores/:storeId/settings',
    children: [
      {
        path: 'settings',
        name: 'settings',
        props: true,
        component: StoreDetail
      },
      {
        path: 'terms',
        name: 'terms',
        props: true,
        component: TermsConditions,
        meta: { skipTerms: true }
      },
      {
        path: 'events',
        name: 'events',
        props: true,
        component: Calendar
      },
      {
        path: 'events/:eventId',
        props: true,
        name: 'event',
        component: EventView,
        children: eventRoutes
      }
    ]
  }
]
