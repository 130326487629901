import { OrganizationSearchFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const OrganizationsSearch = gql`
  query organizationsSearch($searchInput: SearchInput!) {
    organizationsSearch(searchInput: $searchInput) {
      page
      pageSize
      totalResults
      results {
        ...OrganizationSearchFields
      }
    }
  }
  ${OrganizationSearchFields}
`
