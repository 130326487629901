import { gql } from '@apollo/client/core'

export const GameStateTeamFields = gql`
  fragment GameStateTeamFields on TeamV2 {
    teamId
    teamName
    tableNumber
    players {
      personaId
      displayName
      firstName
      lastName
    }
  }
`
