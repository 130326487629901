<template>
  <loading v-if="shouldShowLoading" />
  <div
    v-else
    class="wpn-store-form"
  >
    <div class="wpn-store-form__scroll">
      <div class="wpn-store-form__content">
        <h3 class="wpn-store-form__title">
          {{
            isEditing ? $t('label__edit-store') : $t('title__create-new-store')
          }}
        </h3>
        <div class="wpn-store-form__form theme-admin">
          <div
            id="first-section"
            class="wpn-store-form__form__section"
          >
            <!-- First section -->
            <div class="wpn-store-form__form__store-section">
              <!-- Store section -->
              <store-owner-input
                :model-value="storeOwnerInput"
                :store-owner="storeForm.storeOwner"
                :is-editing="isEditing"
                @update:modelValue="newValue => storeOwnerInput = newValue"
                @saveOwner="(user) => (storeForm.storeOwner = user)"
                @removeStoreOwner="removeStoreOwner"
              />

              <div class="wpn-store-form__form__equal-cols default-padding">
                <ElInput
                  v-model="v$.storeForm.storeName.$model"
                  :validation="v$.storeForm.storeName"
                  field-name="store-name"
                  required
                />
                <ElInput
                  v-model="v$.storeForm.legalName.$model"
                  :validation="v$.storeForm.legalName"
                  field-name="legal-name"
                  required
                />
              </div>
            </div>
            <div class="wpn-store-form__form__status-section default-padding">
              <!-- Status section -->
              <ElSearchSelect
                id="wpnStatus"
                v-model="v$.storeForm.wpnStatus.$model"
                :validation="v$.storeForm.wpnStatus"
                :options="wpnStatuses"
                field-name="wpn-status"
                required
              />
              <button
                v-if="isEditing"
                id="manageFeatures"
                class="btn button-purple"
                @click="$emit('showAddFeatures')"
              >
                {{ $t("title__manage-beta-access") }}
              </button>
              <ElSearchSelect
                id="businessType"
                v-model="v$.storeForm.businessType.$model"
                :validation="v$.storeForm.businessType"
                :options="businessTypes"
                field-name="business-type"
                required
              />
              <div id="showStoreInSEL">
                <ElCheckbox
                  v-model="v$.storeForm.showStoreInSEL.$model"
                  :validation="v$.storeForm.showStoreInSEL"
                  field-name="show-store-in-sel"
                />
              </div>
              <div id="isPremium">
                <ElCheckbox
                  v-model="v$.storeForm.isPremium.$model"
                  :validation="v$.storeForm.isPremium"
                  field-name="is-premium"
                />
              </div>
              <div id="showInInternalSearch">
                <ElCheckbox
                  v-model="v$.storeForm.showInInternalSearch.$model"
                  :validation="v$.storeForm.showInInternalSearch"
                  field-name="show-in-internal-search"
                />
              </div>
            </div>
            <div class="wpn-store-form__form__address-section default-padding">
              <!-- Address section -->
              <div class="wpn-store-form__form__region">
                <!-- Org groups section -->
                <store-org-group-list
                  ref="groupInput"
                  :groups="storeForm.groups"
                  :group-options="storeOptions.groups"
                  @addGroup="addGroup"
                  @removeGroup="removeGroup"
                  @change="groupsUpdated"
                />
              </div>

              <div class="wpn-store-form__form__address">
                <ElInput
                  id="address"
                  ref="autocomplete"
                  v-model="v$.storeForm.address.$model"
                  :validation="v$.storeForm.address"
                  field-name="address1"
                  :placeholder="$t('hint__type-address')"
                  required
                />
                <ElInput
                  id="address2"
                  v-model="v$.storeForm.address2.$model"
                  :validation="v$.storeForm.address2"
                  field-name="address2"
                  :placeholder="$t('hint__type-address')"
                />
              </div>
              <div
                class="wpn-store-form__form__city-postalCode default-space-top"
              >
                <ElInput
                  id="city"
                  v-model="v$.storeForm.city.$model"
                  :validation="v$.storeForm.city"
                  field-name="city"
                  required
                />
                <ElInput
                  id="state"
                  v-model="v$.storeForm.state.$model"
                  :validation="v$.storeForm.state"
                  field-name="state"
                  :label="$t('label__state-subdivision')"
                  required
                />
                <ElInput
                  id="postalCode"
                  v-model="v$.storeForm.postalCode.$model"
                  :validation="v$.storeForm.postalCode"
                  field-name="zip-code"
                  required
                />
              </div>
              <div class="default-space-top">
                <ElInput
                  v-model="v$.storeForm.country.$model"
                  :validation="v$.storeForm.country"
                  field-name="country"
                  required
                />
              </div>
              <div class="wpn-store-form__form__50-50 default-space-top">
                <ElInput
                  v-model.number="v$.storeForm.latitude.$model"
                  :validation="v$.storeForm.latitude"
                  field-name="latitude"
                  :placeholder="$t('hint__type-latitude')"
                  type="number"
                  required
                  @input="updateLatLong"
                />
                <ElInput
                  v-model.number="v$.storeForm.longitude.$model"
                  :validation="v$.storeForm.longitude"
                  field-name="longitude"
                  :placeholder="$t('hint__type-longitude')"
                  type="number"
                  required
                  @input="updateLatLong"
                />
              </div>

              <div
                ref="orgMap"
                class="wpn-store-form__map"
              />
            </div>
            <div class="wpn-store-form__form__distributor-section">
              <!-- Distributor section -->
              <ElSearchSelect
                v-model="v$.storeForm.distributor.$model"
                class="wpn-store-form__form__distributor"
                :validation="v$.storeForm.distributor"
                :options="distributorOptions"
                :disabled="distributorOptions.length <= 1"
                field-name="distributor"
                required
              />
              <div class="wpn-store-form__form__brand-container">
                <StoreBrandList
                  :brands="storeForm.brands"
                  :brand-options="storeOptions.brands"
                  @addBrand="addBrand"
                  @removeBrand="removeBrand"
                />
              </div>
            </div>
          </div>
          <div
            id="second-section"
            class="wpn-store-form__form__section"
          >
            <!-- Second section -->
            <div class="wpn-store-form__form__website-section">
              <!-- Website section -->
              <store-website-group
                ref="websiteInput"
                :websites="storeForm.websites"
                :website-types="websiteTypes"
                @addWebsite="addWebsite"
                @removeWebsite="removeWebsite"
              />
            </div>
            <div class="wpn-store-form__form__phone-section default-padding">
              <!-- Phone section -->
              <store-phone-group
                ref="phoneInput"
                :phones="storeForm.phoneNumbers"
                :phone-types="phoneTypes"
                @addPhoneNumber="addPhoneNumber"
                @removePhoneNumber="removePhoneNumber"
              />
            </div>
            <div class="wpn-store-form__form__contact-section">
              <!-- Contact section -->
              <div class="wpn-store-form__form__equal-cols">
                <ElInput
                  v-model="v$.storeForm.storeEmail.$model"
                  :validation="v$.storeForm.storeEmail"
                  :placeholder="$t('hint__email')"
                  field-name="store-contain-email"
                  required
                />
                <ElCheckbox
                  v-model="v$.storeForm.showStoreEmailInSEL.$model"
                  :validation="v$.storeForm.showStoreEmailInSEL"
                  field-name="show-in-sel"
                  identifier="show-store-email-in-sel"
                />
              </div>
              <div class="wpn-store-form__form__equal-cols default-space-top">
                <ElInput
                  v-model="v$.storeForm.retailTeamEmail.$model"
                  :validation="v$.storeForm.retailTeamEmail"
                  :placeholder="$t('hint__email')"
                  field-name="retail-team-email"
                  required
                />
              </div>
              <div class="wpn-store-form__form__equal-cols default-space-top">
                <ElInput
                  v-model="v$.storeForm.wpnRep.$model"
                  :validation="v$.storeForm.wpnRep"
                  :placeholder="$t('hint__wpn-rep')"
                  field-name="wpn-rep"
                />
              </div>
              <div class="wpn-store-form__form__equal-cols default-space-top">
                <ElSearchSelect
                  v-model="v$.storeForm.language.$model"
                  :validation="v$.storeForm.language"
                  :options="storeOptions.languages"
                  field-name="language"
                  required
                />
              </div>
              <div
                class="
                  wpn-store-form__form__equal-cols wpn-store-form__form__notes
                "
              >
                <ElTextarea
                  v-model="v$.storeForm.notes.$model"
                  class="wpn-store-form__form__notes-textarea"
                  :validation="v$.storeForm.notes"
                  :placeholder="$t('hint__notes')"
                  field-name="special-handling-notes"
                />
              </div>
            </div>
          </div>
          <div class="wpn-store-form__action-bar-spacer">
            <div class="wpn-store-form__action-bar">
              <b-button
                class="button-purple"
                type="submit"
                @click="saveStoreForm"
              >
                {{
                  isEditing
                    ? $t('action__save-changes')
                    : $t('action__create-store')
                }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Loading from '@/components/common/Loading.vue'
import ElCheckbox from '@/components/common/form/ElCheckbox.vue'
import ElInput from '@/components/common/form/ElInput.vue'
import ElSearchSelect from '@/components/common/form/ElSearchSelect.vue'
import ElTextarea from '@/components/common/form/ElTextarea.vue'
import StoreBrandList from '@/components/common/form/StoreBrandList.vue'
import StoreOrgGroupList from '@/components/common/form/StoreOrgGroupList.vue'
import StoreOwnerInput from '@/components/common/form/StoreOwnerInput.vue'
import StorePhoneGroup from '@/components/common/form/StorePhoneGroup.vue'
import StoreWebsiteGroup from '@/components/common/form/StoreWebsiteGroup.vue'
import { DistributorsByGroups, GetStoreOptions } from '@/graphql/queries'
import GRAPHQL_ENUMS from '@/graphqlEnums'
import { getAddressFromGooglePlaceComponents } from '@/util'
import { useVuelidate } from '@vuelidate/core'
import { decimal, email, maxLength, required, requiredIf } from '@vuelidate/validators'
import { v4 as uuid } from 'uuid'
import { defineComponent } from 'vue'

// The only reason we export this is so we can reuse it in the tests
export const defaultStoreForm = () => {
  return {
    storeOwner: null,
    storeName: '',
    legalName: '',
    wpnStatus: null,
    businessType: 'RETAIL',
    showStoreInSEL: false,
    showInInternalSearch: true,
    isPremium: false,
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    latitude: 0,
    longitude: 0,
    distributor: null,
    brands: [{ brandId: '' }],
    // Both website and phone numbers we want to use the `PRIMARY`
    // type for legacy data. So we require the first entry to be `PRIMARY`
    websites: [
      {
        id: uuid(), // only used client side for tracking list updates
        url: '',
        type: 'PRIMARY'
      }
    ],
    phoneNumbers: [
      {
        id: uuid(), // only used client side for tracking list updates
        countryCode: '1',
        phoneNumber: '',
        type: 'PRIMARY',
        showInSEL: false
      }
    ],
    storeEmail: '',
    showStoreEmailInSEL: false,
    retailTeamEmail: '',
    language: null,
    notes: '',
    wpnRep: '',
    groups: [{
      id: ''
    }]
  }
}

export default defineComponent({
  name: 'WpnStoreForm',
  components: { Loading, StoreOwnerInput, StorePhoneGroup, StoreOrgGroupList, StoreWebsiteGroup, StoreBrandList, ElCheckbox, ElInput, ElSearchSelect, ElTextarea },
  props: {
    isEditing: {
      type: Boolean,
      default: false
    },
    initialStoreForm: {
      type: Object,
      default: () => { }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      distributors: [],
      storeOptions: {
        brands: [],
        languages: [],
        regions: [],
        groups: []
      },
      storeOwnerInput: '',
      storeForm: defaultStoreForm(),
      map: null,
      marker: null
    }
  },
  computed: {
    /**
    * Only show loading for this specific query. The distributor query would trigger this
    * every time you change the region so we don't want to have that happen
    */
    shouldShowLoading () {
      return this.$apollo.queries.storeOptions.loading
    },
    websiteTypes () {
      return [
        {
          value: null,
          text: this.$t('label__event-select-option', {
            option: this.$t('label__website-type')
          }),
          disabled: true
        }
      ].concat(GRAPHQL_ENUMS.websiteTypes.map((type) => {
        return {
          value: type.name,
          text: this.localize(type.name)
        }
      }))
    },
    phoneTypes () {
      return [
        {
          value: null,
          text: this.$t('label__event-select-option', {
            option: this.$t('label__phone-type')
          }),
          disabled: true
        }
      ].concat(GRAPHQL_ENUMS.phoneTypes.map((type) => {
        return {
          value: type.name,
          text: this.localize(type.name)
        }
      }))
    },
    wpnStatuses () {
      return [
        {
          value: null,
          text: this.$t('label__event-select-option', {
            option: this.$t('label__wpn-status')
          }),
          disabled: true
        }
      ].concat(GRAPHQL_ENUMS.wpnStatuses.map((status) => {
        return {
          value: status.name,
          text: this.localize(status.name, 'wpn-status-')
        }
      }))
    },
    businessTypes () {
      return [
        {
          value: null,
          text: this.$t('label__event-select-option', {
            option: this.$t('label__business-type')
          }),
          disabled: true
        }
      ].concat(GRAPHQL_ENUMS.businessTypes.map((type) => {
        return {
          value: type.name,
          text: this.localize(type.name, 'business-type-')
        }
      }))
    },
    distributorOptions () {
      return [{
        value: null,
        text: this.$t('label__event-select-option', {
          option: this.$t('label__distributor')
        }),
        disabled: true
      }, ...this.distributors.map(distrib => {
        return {
          value: distrib.distributorId,
          text: distrib.name
        }
      })]
    },
    // Returns a version of the storeForm that we are able to send off to actually create the organization
    mappedStoreForm () {
      const {
        storeOwner,
        storeName,
        websites: inWebsites,
        phoneNumbers: inPhoneNumbers,
        storeEmail,
        wpnStatus,
        showStoreEmailInSEL,
        brands: inBrands,
        distributor: distributorId,
        // More or less throw this away in favor of the computed prop
        groups,
        ...rest
      } = this.storeForm

      // We filter out all falsy values when we submit
      // to make sure we only have good brands
      const brands = inBrands.filter(brand => Boolean(brand.brandId)).map((brand) => {
        return {
          name: brand.brandId
        }
      })

      const phoneNumbers = inPhoneNumbers.map(({ id, ...rest }) => rest)
      const websites = inWebsites.map(({ id, ...rest }) => rest)

      return {
        // SGS will throw an error since this can't be null
        // This computed property should never have a path that leads
        // this value to be used anyway
        storeOwner: storeOwner ? storeOwner.personaId : null,
        name: storeName,
        emailAddress: storeEmail,
        showEmailInSEL: showStoreEmailInSEL,
        website: websites[0].url,
        websites,
        phoneNumber: phoneNumbers[0].countryCode + phoneNumbers[0].phoneNumber,
        phoneNumbers,
        status: wpnStatus,
        brands,
        groups: this.groupIds,
        // Ogre is expecting the id in this format
        distributor: {
          distributorId
        },
        ...rest
      }
    },
    groupIds () {
      // Filter out any groups that don't have an id
      return this.storeForm.groups.filter(group => group.id).map((group) => {
        return group.id
      })
    }
  },
  async mounted () {
    if (this.isEditing) {
      this.storeForm = {
        ...defaultStoreForm(),
        ...this.initialStoreForm
      }
      // We need the id's to be unique if they don't exist so vue knows exactly which element was
      // remved if we remove an entry in the middle of these lists
      for (const website of this.storeForm.websites) {
        if (website.id === undefined || website.id === null) {
          website.id = uuid()
        }
      }

      for (const phone of this.storeForm.phoneNumbers) {
        if (phone.id === undefined || phone.id === null) {
          phone.id = uuid()
        }
      }
    }
  },
  validations () {
    return {
      storeForm: {
        storeName: {
          required
        },
        legalName: {
          required,
          maxLength: maxLength(2048)
        },
        wpnStatus: {
          required
        },
        businessType: {
          required,
          maxLength: maxLength(255)
        },
        showStoreInSEL: {},
        showInInternalSearch: {},
        isPremium: {},
        address: {
          required,
          maxLength: maxLength(255)
        },
        address2: { maxLength: maxLength(255) },
        city: {
          required,
          maxLength: maxLength(255)
        },
        state: {
          required,
          maxLength: maxLength(255)
        },
        postalCode: {
          required,
          maxLength: maxLength(16)
        },
        country: {
          required,
          maxLength: maxLength(255)
        },
        latitude: {
          required,
          decimal
        },
        longitude: {
          required,
          decimal
        },
        distributor: {
          required: requiredIf(() => {
            return this.distributorOptions.length > 1
          })
        },
        storeEmail: {
          required,
          email,
          maxLength: maxLength(320)
        },
        showStoreEmailInSEL: {},
        retailTeamEmail: {
          required,
          email,
          maxLength: maxLength(320)
        },
        language: {
          required,
          maxLength: maxLength(8)
        },
        notes: {},
        wpnRep: {
          maxLength: maxLength(320)
        }
      }
    }
  },
  apollo: {
    distributors: {
      query: DistributorsByGroups,
      variables () {
        return {
          groupIds: this.groupIds
        }
      },
      // We don't want to execute the query unless we have a group to query
      skip () {
        return !this.groupIds || !this.groupIds.length
      },
      update ({ distributorsByGroups }) {
        return distributorsByGroups
      }
    },
    storeOptions: {
      query: GetStoreOptions,
      variables () {
        return {
          locale: this.$i18n.locale
        }
      },
      update ({ regions, languages, groups, brands, ...rest }) {
        // Once we get back the results of this query we map all of the different options into formats
        // that work with our Select's as well as prepend a default option for the array that is disabled
        // All of these options then end up being ready to use right away
        return {
          brands: [{
            value: '',
            text: this.$t('label__event-select-option', {
              option: this.$t('label__brand')
            }),
            disabled: true
          }, ...brands.map(brand => {
            return {
              value: brand.brandId,
              text: brand.brandName
            }
          })],
          languages: [{
            value: null,
            text: this.$t('label__event-select-option', {
              option: this.$t('label__language')
            }),
            disabled: true
          }, ...languages.map(lang => {
            return {
              value: lang.code,
              text: lang.name
            }
          })],
          groups: [{
            value: '',
            text: this.$t('label__event-select-option', {
              option: this.$t('label__group')
            }),
            disabled: true
          }, ...regions.map(region => {
            return {
              value: region.id,
              text: region.name,
              badges: [{ name: this.$t('label__region') }]
            }
          })].concat(groups.map(group => {
            return {
              value: group.id,
              text: group.name
            }
          })),
          ...rest
        }
      }
    }
  },

  updated () {
    // prevent initializing the map more than once or if the page isn't loaded
    if (this.shouldShowLoading || this.map) {
      return
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      (this.$refs.autocomplete.$refs.input),
      { types: ['address'] }
    )
    this.autocomplete.addListener('place_changed', this.setPlace)
    const location = new google.maps.LatLng(this.storeForm.latitude || 0, this.storeForm.longitude || 0)
    const options = {
      zoom: 15,
      center: location,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    const mapDiv = this.$refs.orgMap
    this.map = new google.maps.Map(mapDiv, options)
    this.marker = new google.maps.Marker({ position: location, map: this.map, draggable: true })
    this.marker.addListener('dragend', this.onLocationDragEnd)
  },
  beforeDestroy () {
    if (this.marker) {
      google.maps.event.clearListeners(this.marker, 'dragend')
    }
    if (this.autocomplete) {
      google.maps.event.clearListeners(this.autocomplete, 'place_changed')
    }
  },
  methods: {
    updateLatLong () {
      if (this.marker) {
        const location = new google.maps.LatLng(this.storeForm.latitude || 0, this.storeForm.longitude || 0)
        this.marker.setPosition(location)
        this.map.setCenter(location)
      }
    },
    setPlace () {
      this.savedPlace = this.autocomplete.getPlace()
      /* eslint-disable */
      const { address_components, geometry: { location = null } = {} } = this.savedPlace

      const { streetAddress, city, state, postalCode, country } = getAddressFromGooglePlaceComponents(address_components);

      this.storeForm.address = streetAddress // formatted_address
      this.storeForm.city = city
      this.storeForm.state = state
      this.storeForm.postalCode = postalCode
      this.storeForm.country = country

      /* eslint-enable */
      if (location) {
        this.storeForm.latitude = location.lat()
        this.storeForm.longitude = location.lng()
        this.marker.setPosition(location)
        this.map.setCenter(location)
      }
    },
    onLocationDragEnd () {
      if (!this.marker) {
        return
      }
      this.storeForm.latitude = +this.marker.position.lat().toFixed(5) // adding the + to the front drops extra 0s
      this.storeForm.longitude = +this.marker.position.lng().toFixed(5)
      this.map.setCenter(this.marker.position)
    },
    localize (name, labelIdentifier = '') {
      if (!name) return ''
      return this.$t('label__' + labelIdentifier + name.toLowerCase().replace(/_/g, '-'))
    },
    removeStoreOwner () {
      this.storeOwnerInput = ''
      this.storeForm.storeOwner = null
    },
    addWebsite () {
      this.storeForm.websites.push({
        id: uuid(), // only used client side for tracking list updates
        url: '',
        type: null
      })
    },
    removeWebsite (idx) {
      this.storeForm.websites.splice(idx, 1)
    },
    addPhoneNumber () {
      this.storeForm.phoneNumbers.push({
        id: uuid(), // only used client side for tracking list updates
        countryCode: '1',
        phoneNumber: '',
        type: null,
        showInSEL: false
      })
    },
    removePhoneNumber (idx) {
      this.storeForm.phoneNumbers.splice(idx, 1)
    },
    addGroup () {
      this.storeForm.groups.push({
        id: ''
      })
    },
    removeGroup (idx) {
      this.storeForm.groups.splice(idx, 1)
      if (this.storeForm.groups.length < 1) {
        this.addGroup()
      }
    },
    addBrand () {
      this.storeForm.brands.push({ brandId: '' })
    },
    removeBrand (idx) {
      this.storeForm.brands.splice(idx, 1)
      if (this.storeForm.brands.length < 1) {
        this.addBrand()
      }
    },
    groupsUpdated () {
      // We reset the selected distributor anytime the region
      // is updated since all region will have different options
      // for distributors and they all will not share them either
      this.storeForm.distributor = null
    },
    resetStoreForm () {
      // Reset the full forms validation
      this.storeOwnerInput = ''
      this.storeForm = defaultStoreForm()
      this.$nextTick(() => {
        this.v$.$reset()
      })
    },
    async saveStoreForm () {
      await this.v$.$validate()

      if (this.v$.$invalid) {
        return
      }

      this.$emit('saveStoreForm', { form: this.mappedStoreForm, resetForm: this.resetStoreForm })
    }
  }
})
</script>

<style lang="scss">
.wpn-store-form {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $white;
  box-sizing: content-box;

  .wpn-store-form__map {
    width: 100%;
    height: 280px;
    margin-top: 20px;
  }

  .wpn-store-form__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $purple-admin;
    padding: 30px;

    .wpn-store-form__title {
      font: $fontstyle-heading3;
      align-self: flex-start;
      padding-bottom: 30px;
    }

    .wpn-store-form__form {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-areas: 'first second';
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 40px;
      position: relative;

      @include sm {
        grid-template-areas: 'first' 'second';
        grid-template-columns: minmax(0, 1fr);
      }

      #first-section {
        grid-area: first;
      }
      #second-section {
        grid-area: second;
        @include sm {
          padding-top: 30px;
        }
      }

      .wpn-store-form__action-bar-spacer {
        // We don't want to block the form controls on the smaller screens
        // This will make some extra scrollable area that will allow all of
        // the form to be filled out as needed
        height: 90px;

        .wpn-store-form__action-bar {
          // 90px accounts for the left-hand sidebar
          // 60px accounts for the margin we add to each side
          width: calc(100% - 90px - 60px);
          position: fixed;
          bottom: 0px;
          left: 90px;
          margin: 30px;
          background-color: $white;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 10px;
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          z-index: 1030;
        }
      }

      .required::after {
        content: unset;
      }

      .default-padding {
        padding: 30px 0;
      }
      .default-space-top {
        padding-top: 10px;
      }

      .wpn-store-form__form__equal-cols {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        > * {
          width: 100%;
        }
      }

      .wpn-store-form__form__50-50 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        > * {
          width: 50%;
        }
        > :last-child {
          width: 50%;
        }
      }

      .wpn-store-form__form__region {
        padding-bottom: 30px;
      }

      .wpn-store-form__form__section {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .wpn-store-form__form__store-section,
        .wpn-store-form__form__status-section,
        .wpn-store-form__form__address-section,
        .wpn-store-form__form__website-section,
        .wpn-store-form__form__phone-section {
          border-bottom: 1px solid $purple-admin;
        }

        .wpn-store-form__form__address-section {
          .wpn-store-form__form__address {
            display: grid;
            grid-template-areas: 'address address2';
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 10px;

            @include xs {
              grid-template-areas: 'address' 'address2';
              grid-template-columns: 1fr;
              row-gap: 10px;
            }

            #address {
              grid-area: address;
            }
            #address2 {
              grid-area: address2;
            }
          }
          .wpn-store-form__form__city-postalCode {
            display: grid;
            grid-template-areas: 'city state postalCode';
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 10px;

            @include xs {
              grid-template-areas: 'city' 'state' 'postalCode';
              grid-template-columns: 1fr;
              row-gap: 10px;
            }

            #city {
              grid-area: city;
            }
            #state {
              grid-area: state;
            }
            #postalCode {
              grid-area: postalCode;
            }
          }
        }

        .wpn-store-form__form__status-section {
          display: grid;
          grid-template-areas:
            'status type beta'
            'sel premium internal';
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-template-rows: repeat(2, minmax(0, 1fr));
          row-gap: 10px;
          column-gap: 10px;

          #wpnStatus {
            grid-area: status;
            width: 100%;
          }
          #businessType {
            grid-area: type;
            width: 100%;
          }
          #manageFeatures {
            grid-area: beta;
            width: 100%
          }

          #showStoreInSEL {
            grid-area: sel;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
           #showInInternalSearch {
            grid-area: internal;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          #isPremium {
            grid-area: premium;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .wpn-store-form__form__website-section {
          padding-bottom: 30px;
        }

        .wpn-store-form__form__distributor-section {
          padding-top: 30px;

          .wpn-store-form__form__distributor {
            padding-bottom: 30px;
            border-bottom: 1px solid $purple-admin;
          }

          .wpn-store-form__form__brand-container {
            padding-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @include md {
              padding-top: 30px;
              flex-direction: column;
            }
          }
        }

        .wpn-store-form__form__contact-section {
          padding-top: 30px;
          flex: 2;
          display: flex;
          flex-direction: column;

          .wpn-store-form__form__notes {
            padding-top: 10px;
            flex: 2;

            .wpn-store-form__form__notes-textarea {
              height: 100%;
              & div {
                height: 100%;
              }
              & textarea {
                min-height: 6rem;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
