<template>
  <div class="store-website-group">
    <store-website-input
      v-for="(website, idx) in websites"
      :key="website.id"
      :index="idx"
      :website="website"
      :website-types="websiteTypes"
      @removeWebsite="$emit('removeWebsite', idx)"
    />
    <div
      class="store-website-group__actions"
      :class="{ 'disabled': addDisabled }"
      @click="!addDisabled && $emit('addWebsite')"
    >
      <circle-plus-icon
        purple
        large
      />
      <span class="store-website-group__action-text">
        {{ $t('action__add-website') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import StoreWebsiteInput from '@/components/common/form/StoreWebsiteInput.vue'
import CirclePlusIcon from '@/components/common/icons/CirclePlusIcon.vue'
import CONSTANTS from '@/constants'
import { SelectOption } from '@/types/forms'
import { Website } from '@/types/wpn-types'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  websites: Array<Website>
  websiteTypes: Array<SelectOption>
}>()

defineEmits(['addWebsite', 'removeWebsite'])

const rules = {
  websites: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(CONSTANTS.MAX_STORE_WEBSITES)
  }
}
const v$ = useVuelidate(rules, props)
const addDisabled = computed(() => {
  return v$.value.$invalid || props.websites.length >= CONSTANTS.MAX_STORE_WEBSITES
})
</script>

<style lang="scss">
.store-website-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .store-website-group__actions {
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .icon.icon--circle-plus {
      width: 30px;
      height: 30px;
    }
    .store-website-group__action-text {
      margin: 0px 10px;
      font: $fontstyle-link;
      line-height: 16px;
    }
  }
}
</style>
