<template>
  <div
    class="el-form-group"
    :class="{ invalid, disabled }"
  >
    <label :for="componentId">
      {{ fieldLabel }}
    </label>
    <textarea
      :id="componentId"
      ref="input"
      :value="value"
      :placeholder="inputPlaceholder"
      :disabled="disabled"
      :required="required"
      :type="type"
      @input="onEvent('input', $event.target)"
      @change="onEvent('change', $event.target)"
    />
    <CircleExclamation
      v-if="invalid"
      :color="Colors.DANGER"
    />
  </div>
</template>

<script setup lang="ts">
import { useFormComponent } from '@/composables/forms/useFormComponent'
import { Colors, HTMLInputTypeAttribute, Nullable } from '@/types'
import { BaseValidation } from '@vuelidate/core'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n-bridge'
import CircleExclamation from '../icons/CircleExclamation.vue'

const props = withDefaults(defineProps<{
  identifier?: string;
  fieldName: string;
  label?: string;
  validation?: BaseValidation;
  disabled?: boolean;
  required?: boolean;
  shortRequired?: boolean;
  type?: HTMLInputTypeAttribute;
  value?: string;
  placeholder?: string;
}>(), {
  identifier: undefined,
  label: undefined,
  placeholder: undefined,
  validation: undefined,
  value: '',
  required: false,
  shortRequired: false,
  type: 'text'
})

const emit = defineEmits(['input', 'change'])
const onEvent = (type: 'input' | 'change', element: Nullable<EventTarget>) => {
  emit(type, (element as HTMLTextAreaElement).value)
}

const inputVal = ref(props.value)
watch(inputVal, (newVal) => {
  emit('input', newVal)
})

const { t } = useI18n()
const { invalid, labelName, fieldLabel, componentId } = useFormComponent(props)

const inputPlaceholder = computed(() => {
  return props.placeholder
    ? props.placeholder
    : t('label__enter-field', { field: labelName.value })
})
</script>
