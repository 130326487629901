import { gql } from '@apollo/client/core'

export const StandingFields = gql`
  fragment StandingFields on TeamStandingV2 {
    teamId
    rank
    wins
    losses
    draws
    matchPoints
    gameWinPercent
    opponentGameWinPercent
    opponentMatchWinPercent
  }
`
