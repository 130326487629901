import { gql } from '@apollo/client/core'

export const UserSearch = gql`
  query userSearch($input: UserSearchInput!) {
    userSearch(input: $input) {
      page
      pageSize
      totalResults
      results {
        id
        personaId
        roleName

        organization {
          id
          name
          groups
        }
      }
    }
  }
`
