import { gql } from '@apollo/client/core'

export const RegistrationFields = gql`
  fragment RegistrationFields on Registration {
    id
    personaId
    displayName
    firstName
    lastName
    status
    preferredTableNumber
  }
`
