// Util contains A LOT of stuff and I did not want to type it now

import CONSTANTS from '@/constants'
import { Dictionary, ID, KeyOf, Nullable } from '@/types'
import { ChangedPodSeat, Draft, GameStateQuery, HydratedGameState, Match, Pod, Round, Seat, Standing, Team } from '@/types/gameState'
import { HydratedIncident, Incident } from '@/types/incidents'
import { Registration, TwoHeadedGiantTeam } from '@/types/registrations'
import { Timer, TimerState } from '@/types/timer'
import { getVueInstance } from '@/vm'
import { cloneDeep, filter, has, omit, pickBy } from 'lodash'
import { DateTime } from 'luxon'
import { DATETIME_PENALTIES } from './format'

const tableNumberCompare = (a: Nullable<number>, b: Nullable<number>) => {
  if (a === b) {
    return 0
  }
  if (a === null) {
    return 1
  }
  if (b === null) {
    return -1
  }
  return a < b ? 1 : -1
}

export function handleChangedMatches (gameState: GameStateQuery, roundNumber: number, changedMatches: Match[]): Nullable<GameStateQuery> {
  const roundIdx = gameState.rounds.findIndex(r => r.roundNumber === roundNumber)
  if (roundIdx === -1) {
    // No update to make
    return null
  }

  const currentMatches = cloneDeep(gameState.rounds[roundIdx].matches)

  const matches = []
  const changedTeamIds = changedMatches.flatMap(m => m.teamIds)
  for (const match of currentMatches) {
    if (!match.teamIds.some(id => changedTeamIds.includes(id))) {
      matches.push(match)
    }
  }

  // Add all modified matches
  matches.push(...changedMatches)

  matches.sort((a, b) => {
    if (a.isBye && !b.isBye) {
      return 1
    }
    if (!a.isBye && b.isBye) {
      return -1
    }

    const aCount = a.teamIds.length
    const bCount = b.teamIds.length
    return aCount < bCount ? 1 : (aCount === bCount ? tableNumberCompare(a.tableNumber, b.tableNumber) : -1)
  })

  const newRound = {
    ...gameState.rounds[roundIdx],
    matches
  }

  const rounds = cloneDeep(gameState.rounds)
  rounds[roundIdx] = newRound

  return {
    ...gameState,
    rounds
  }
}

export function handleChangedStandings (gameState: GameStateQuery, roundNumber: number, changedStandings: Standing[]): Nullable<GameStateQuery> {
  const roundIdx = gameState.rounds.findIndex(r => r.roundNumber === roundNumber)
  if (roundIdx === -1) {
    // No update to make
    return null
  }

  const currentStandings = cloneDeep(gameState.rounds[roundIdx].standings)

  const standings = []
  const changedTeamIds = changedStandings.map(s => s.teamId)
  for (const standing of currentStandings) {
    if (!changedTeamIds.includes(standing.teamId)) {
      // Add back in all the unchanged standings
      standings.push(standing)
    }
  }

  // Add all modified standings
  standings.push(...changedStandings)

  // Sort by rank which should be the default
  standings.sort((a, b) => b.rank - a.rank)

  const newRound = {
    ...gameState.rounds[roundIdx],
    standings
  }

  const rounds = cloneDeep(gameState.rounds)
  rounds[roundIdx] = newRound

  return {
    ...gameState,
    rounds
  }
}

export function handledChangeDraft (gameState: GameStateQuery, isPlayoff: boolean, changedPodSeats: ChangedPodSeat[]): Nullable<GameStateQuery> {
  const draft = isPlayoff ? gameState.playoffDraft : gameState.draft
  if (!draft || !draft.pods?.length) {
    // No update to make
    return null
  }

  // Look through all current pods, if we can't find them in `changedPodSeats` it means they were left alone
  const unchangedPodSeats: ChangedPodSeat[] = draft.pods.flatMap(pod => pod.seats.filter(seat => changedPodSeats.findIndex(change => change.teamId === seat.teamId) === -1).map(seat => ({ podNumber: pod.podNumber, seatNumber: seat.seatNumber, teamId: seat.teamId })))

  const newPods: Pod[] = []

  // First add all of the changed pods
  for (const podSeat of changedPodSeats.concat(unchangedPodSeats)) {
    const teamSeat: Seat = { seatNumber: podSeat.seatNumber, teamId: podSeat.teamId, __typename: 'SeatV2' }
    // Create the pod if it does not exist in the new array yet
    const podIdx = newPods.findIndex(p => p.podNumber === podSeat.podNumber)
    if (podIdx === -1) {
      newPods.push({
        podNumber: podSeat.podNumber,
        seats: [teamSeat],
        __typename: 'PodV2'
      })
    } else {
      const pod = newPods[podIdx]
      // Add the new seat
      pod.seats.push(teamSeat)
    }
  }

  // Sort each pod seats
  for (const pod of newPods) {
    pod.seats.sort((a, b) => a.seatNumber < b.seatNumber ? -1 : 1)
  }

  // Sort each pod by pod number
  newPods.sort((a, b) => a.podNumber < b.podNumber ? -1 : 1)

  const newDraft: Draft = {
    pods: newPods,
    timerId: draft.timerId,
    __typename: 'DraftV2'
  }

  const fieldName: KeyOf<GameStateQuery> = isPlayoff ? 'playoffDraft' : 'draft'
  return {
    ...gameState,
    [fieldName]: newDraft
  }
}

type AllowedQueryParam = number | boolean | string | undefined | (string | null)[]
type RouteQueryParam = string | undefined | (string | null)[]
export const routeFromQuery = (query: Dictionary<AllowedQueryParam>): { query: Dictionary<RouteQueryParam> } => {
  const vm = getVueInstance()

  // NB: depends on you not passing in more than one of the below listed params
  const mutuallyExclusiveParams = ['show', 'edit', 'create', 'recurrence']
  if (mutuallyExclusiveParams.find(k => has(query, k))) {
    const oldQuery = omit(vm.$route.query, mutuallyExclusiveParams)
    const newParams = { ...oldQuery, ...query }
    return { query: pickBy<string>(newParams, p => p !== undefined && p !== null) } // omit keys with null values
  }

  // Convert any unsupported query types (boolean and number) to string automatically
  const stringQuery: { [key: string]: RouteQueryParam } = {}
  for (const [key, value] of Object.entries(query)) {
    if (value) {
      if (Array.isArray(value)) {
        // Leave an array input alone as it should already be acceptable
        stringQuery[key] = value
      } else {
        stringQuery[key] = String(value)
      }
    } else {
      stringQuery[key] = undefined
    }
  }

  return { query: { ...vm.$route.query, ...stringQuery } }
}

export const hydratedGameState = ({
  teams,
  ...rest
}: GameStateQuery): HydratedGameState => {
  teams = teams ?? []
  const teamsMap: { [key: ID]: Team } = {}
  for (const team of teams) {
    teamsMap[team.teamId] = team
  }

  return {
    teams: teamsMap,
    ...rest
  }
}

const isID = (item: ID | undefined): item is ID => {
  return !!item
}

/**
 * Given a list of PersonaIds and Teams find all the team ids for each player
 * @param personaIds
 * @param teams
 * @returns A list of the teamIds that would be for each player
 */
export const getTeamIdsFromPersonas = (personaIds: ID[], teams: Team[]) => {
  // To see if a team is a match we have to check in the players array
  const ids = personaIds.map(pId => teams.find(t => t.players.find(player => player.personaId === pId))?.teamId)
  const goodIds: ID[] = ids.filter(isID)

  if (goodIds.length !== ids.length) {
    // TODO: Should we throw an error here? This seems bad
    console.warn('Failed to find all teams for personas!')
  }

  return goodIds
}

export const calculateEventPlayoffRounds = (hasPlayoff: boolean, hasDraft: boolean, requiredTeamSize: number, registeredPlayers: Registration[], teams: TwoHeadedGiantTeam[]): number => {
  if (!hasPlayoff) {
    return 0
  }

  const registrantCount = getRegistrantCount(requiredTeamSize, registeredPlayers, teams)
  const hasTop4 = registrantCount >= CONSTANTS.TOP4_CUTOFF_COUNT && registrantCount < CONSTANTS.TOP8_CUTOFF_COUNT && !hasDraft
  const hasTop8 = registrantCount >= CONSTANTS.TOP8_CUTOFF_COUNT || (registrantCount >= CONSTANTS.TOP4_CUTOFF_COUNT && hasDraft)
  return hasTop4 ? CONSTANTS.TOP4_PLAYOFF_ROUNDS : hasTop8 ? CONSTANTS.TOP8_PLAYOFF_ROUNDS : 0
}

export const getRegistrantCount = (requiredTeamSize: number, registeredPlayers: Registration[], teams: TwoHeadedGiantTeam[]) => {
  const isSoloEvent = requiredTeamSize === 1
  // 2HG events get number of registered teams, non 2HG use number of registered players
  return isSoloEvent ? (registeredPlayers ?? []).length : (teams ?? []).filter(t => t.isRegistered).length
}

interface PlayoffFields {
  currentRoundNumber: number;
  minRounds: number;
  playoffRounds: number;
}

export const isInPlayoffRound = ({ currentRoundNumber, minRounds, playoffRounds }: PlayoffFields) => playoffRounds > 0 && currentRoundNumber > minRounds

const getCurrentPlayoffRound = (playoffFields: PlayoffFields, nextRound: boolean = false) => nextRound ? (playoffFields.currentRoundNumber + 1) - playoffFields.minRounds : playoffFields.currentRoundNumber - playoffFields.minRounds

// 1 is just final
// 2 is semifinal and final
// 3 is quarterfinal, semifinal, and final

export const isQuarterFinal = (playoffFields: PlayoffFields, nextRound?: boolean) => {
  if (!isInPlayoffRound(playoffFields)) {
    return false
  }

  return playoffFields.playoffRounds > 2 && playoffFields.playoffRounds - 2 === getCurrentPlayoffRound(playoffFields, nextRound)
}

export const isSemiFinal = (playoffFields: PlayoffFields, nextRound?: boolean) => {
  if (!isInPlayoffRound(playoffFields)) {
    return false
  }

  return playoffFields.playoffRounds > 1 && playoffFields.playoffRounds - 1 === getCurrentPlayoffRound(playoffFields, nextRound)
}

export const isFinal = (playoffFields: PlayoffFields, nextRound?: boolean) => {
  if (!isInPlayoffRound(playoffFields)) {
    return false
  }

  return playoffFields.playoffRounds === getCurrentPlayoffRound(playoffFields, nextRound)
}

export const getRoundAt = ({
  rounds = []
}: HydratedGameState, roundNumber: number | string): Nullable<Round> => {
  if (!rounds) {
    return null
  }
  // Sometimes we deal with numbers sometimes strings
  // Let's make sure we always are comaring against the same types
  return rounds.find(x => String(x.roundNumber) === String(roundNumber)) ?? null
}

export const getCurrentRound = (gameState: HydratedGameState): Nullable<Round> => {
  if (!gameState) {
    return null
  }
  return getRoundAt(gameState, gameState.currentRoundNumber)
}

export const teamPodNumber = (teamId: ID, pods?: Pod[]) => {
  const podObj = filter(pods, { seats: [{ teamId }] })
  return podObj && podObj.length ? String(podObj[0].podNumber) : ''
}

export const hydratedIncidents = (data: Incident[], isOnline = false): HydratedIncident[] => {
  if (!data) return []
  return data.map(element => ({
    id: element.id,
    round: element.roundNumber,
    infractionId: element.infraction.id,
    playerName: isOnline ? element.offender.displayName : element.offender.firstName + ' ' + element.offender.lastName,
    penaltyId: element.penalty.id,
    timestamp: DateTime.fromISO(element.reportedAt).toFormat(DATETIME_PENALTIES)
  }))
}

export const calculatedEndTime = (timer?: Timer) => {
  if (!timer || timer.state !== TimerState.RUNNING) {
    return null
  }

  const { serverTime, durationStartTime, durationMs } = timer

  const serverDateTime = DateTime.fromISO(serverTime).toMillis()
  const durationStartDateTime = DateTime.fromISO(durationStartTime).toMillis()
  const localDateTime = DateTime.local().toMillis()

  const serverNow = serverDateTime - (serverDateTime - localDateTime)
  const passedSec = serverNow - durationStartDateTime
  return durationMs - passedSec
}
