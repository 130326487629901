const CONSTANTS = {
  YES: 'YES',
  NO: 'NO',
  GRAPHQL_MAX_INT: 2147483647, // TODO: Remove this when we upgrade GraphQL and use the const they export
  TOP8_CUTOFF_COUNT: 17,
  TOP4_CUTOFF_COUNT: 9,
  TOP8_MIN_ROUNDS: 4,
  TOP4_PLAYOFF_ROUNDS: 2,
  TOP8_PLAYOFF_ROUNDS: 3,
  MAGIC_BRAND_ID: 'MAGIC_THE_GATHERING',
  BULK_IMPORT_ERROR_MODAL: 'bulk-import-error-modal',
  CONFIRM_EDIT_PAIRING_MODAL: 'confirm-edit-pairing-modal',
  MISSING_DATA: '-',
  IS_DEBUG: import.meta.env.DEV,
  CUSTOM_EVENT_KEY: 'CUSTOM',
  KNOWN_ROLES: {
    WPN_ADMIN: 'WPN_ADMIN',
    STORE_OWNER: 'STORE_OWNER',
    STORE_ADMIN: 'STORE_ADMIN',
    SCOREKEEPER: 'SCOREKEEPER'
  },
  EXPORT_TYPE: {
    STORE: 'store',
    USER: 'user'
  },
  FEATURES: {
    MANUAL_PAIRING: 'Manual_Pairing',
    COMMANDER_SUPPORT: 'Commander_Support',
    DISABLE_GUESTS: 'Disable_Guests',
    CALENDAR_FILTER: 'Calendar_Filter'
  },
  // Do we want a limit? Do we see a store ever hitting 32 groups?
  MAX_STORE_GROUPS: 32,
  MAX_STORE_WEBSITES: 4,
  MAX_STORE_PHONE_NUMBERS: 5,
  MS: 1000,
  MIN_TIMER: -5999000,
  MAX_TIMER: 5999000,
  // 30 second timeout
  TIMER_TIMEOUT: 30000,
  TAB_SYNC_EVENTS: {
    ACTIVITY: 'activity',
    AUTH_REFRESHED: 'authRefreshed',
    LOGOUT: 'logout'
  },
  TIMER_STATES: {
    DELETED: 'DELETED',
    HALTED: 'HALTED',
    RUNNING: 'RUNNING'
  },
  DEFAULT_TIMER_DURATION: 3000000,
  NOTIFICATION_TYPES: {
    DEFAULT: 'default',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
  },
  SORTABLE_FIELDS: {
    DEFAULT: 'default',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DISPLAY_NAME: 'displayName',
    PLAYER_N: 'player_'
  },
  SORT_ORDERS: {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
  },
  REGISTRATION_STATUSES: {
    GUEST: 'GUEST'
  },
  EVENT_STATUSES: {
    SCHEDULED: 'SCHEDULED',
    PLAYERREGISTRATION: 'PLAYERREGISTRATION',
    ENDED: 'ENDED',
    DRAFTING: 'DRAFTING',
    DECKCONSTRUCTION: 'DECKCONSTRUCTION',
    ROUNDREADY: 'ROUNDREADY',
    ROUNDACTIVE: 'ROUNDACTIVE',
    ROUNDCERTIFIED: 'ROUNDCERTIFIED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED'
  },
  CONFIRMATION_STATES: {
    NONE: 'NONE',
    STANDBY: 'STANDBY',
    SINGLE_ELIMINATION: 'SINGLE_ELIMINATION',
    TOP8_DRAFT: 'TOP8_DRAFT'
  },
  SESSION_TIMEOUT: {
    WPN_ADMIN: 15 * 60, // Multiply by 60 because this is stored in seconds
    DEFAULT: 720 * 60
  },
  BROWSER_INVALID_TEMPLATE_DAYS: -1,
  BROWSER_MAX_DAYS: 1000,
  ADDRESS_FIELDS: {
    ADDRESS: 'address',
    ADDRESS2: 'address2',
    CITY: 'city',
    STATE: 'state',
    POSTAL_CODE: 'postalCode',
    COUNTRY: 'country'
  },
  MAX_EVENT_INSTANCES: 10,
  EVENT_FORM: {
    CREATE: 'create',
    EDIT: 'edit'
  },
  EVENT_FORM_FIELDS: {
    TITLE: 'title',
    DESCRIPTION: 'description',
    FORMAT: 'eventFormatId',
    CARD_SET: 'cardSetId',
    REL: 'rulesEnforcementLevel',
    PAIRING_TYPE: 'pairingType',
    VENUE: 'venueId',
    CAPACITY: 'capacity',
    START_TIME: 'startTime',
    END_TIME: 'endTime',
    REPEAT_UNTIL: 'repeatUntil',
    ENTRY_FEE: 'entryFeeAmount',
    DOTW_MASK: 'dotWMask',
    ENTRY_CURRENCY: 'entryFeeCurrency',
    DATE: 'date',
    TIMEZONE: 'timeZone',
    HAS_TOP8: 'hasTop8',
    IS_ADHOC: 'isAdHoc',
    IS_ONLINE: 'isOnline',
    FREQUENCY: 'frequency',
    IS_DOTW_BOUND: 'isDotWBound',
    TEMPLATE_ID: 'eventTemplateId',
    REQUIRED_TEAM_SIZE: 'requiredTeamSize'
  },
  CONTENTFUL_FIELD_RULES: {
    MUST_MATCH: 'MUST_MATCH',
    MUST_CONTAIN: 'MUST_CONTAIN',
    AVAILABLE_OPTIONS: 'AVAILABLE_OPTIONS',
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    DAY_OF_THE_WEEK: 'DAY_OF_THE_WEEK',
    DATE_RANGE: 'DATE_RANGE',
    REQUIRE_STORE: 'REQUIRE_STORE',
    STARTS_WITH: 'STARTS_WITH',
    ENDS_WITH: 'ENDS_WITH'
  },
  CONTENTFUL_FIELD_MAPPINGS: {
    Title: 'title',
    Format: 'eventFormatId',
    Set: 'cardSetId',
    'Rules Enforcement Level': 'rulesEnforcementLevel',
    'Pairing Method': 'pairingType',
    'Entry Fee': 'entryFeeAmount',
    Currency: 'entryFeeCurrency',
    Description: 'description',
    Date: 'date',
    Venue: 'venueId',
    'Is Online': 'isOnline',
    'Is 2HG': 'requiredTeamSize'
  },
  FIELD_TO_TRANSLATION: {
    title: 'title',
    eventFormatId: 'format',
    cardSetId: 'card-set',
    rulesEnforcementLevel: 'rules-enforcement-level',
    pairingType: 'pairing-type',
    entryFeeAmount: 'entry-fee',
    entryFeeCurrency: 'currency',
    description: 'description',
    date: 'date',
    venueId: 'venue',
    isOnline: 'is-online',
    startTime: 'start-time',
    endTime: 'end-time',
    requiredTeamSize: 'is2hg'
  },
  FIELD_TO_CONTENTFUL: {
    title: 'title',
    eventFormatId: 'format',
    cardSetId: 'set',
    rulesEnforcementLevel: 'rules-enforcement-level',
    pairingType: 'pairing-method',
    entryFeeAmount: 'entry-fee',
    entryFeeCurrency: 'currency',
    description: 'description',
    date: 'date',
    venueId: 'venue',
    isOnline: 'is-online',
    requiredTeamSize: 'is-2hg',
    repeatUntil: 'date'
  },
  TEMPLATE_TYPES: {
    NONE: 'None',
    PRERELEASE: 'Prerelease'
  },
  PRERELEASE_TYPES: {
    AT_HOME: 'At Home',
    IN_STORE: 'In Store',
    WEBCAM: 'Webcam'
  },
  LAST_TERMS_UPDATE: '2019-12-01',
  CURRENCIES: [
    // Manually placing some more used ones at the top for ease of access
    { value: 'USD', text: 'US Dollar' },
    { value: 'EUR', text: 'Euro' },
    { value: 'GBP', text: 'Pound Sterling' },
    { value: 'AUD', text: 'Australian Dollar' },
    { value: 'BRL', text: 'Brazilian Real' },

    { value: 'AED', text: 'UAE Dirham' },
    { value: 'AFN', text: 'Afghani' },
    { value: 'ALL', text: 'Lek' },
    { value: 'AMD', text: 'Armenian Dram' },
    { value: 'ANG', text: 'Netherlands Antillean Guilder' },
    { value: 'AOA', text: 'Kwanza' },
    { value: 'ARS', text: 'Argentine Peso' },
    { value: 'AWG', text: 'Aruban Florin' },
    { value: 'AZN', text: 'Azerbaijanian Manat' },
    { value: 'BAM', text: 'Convertible Mark' },
    { value: 'BBD', text: 'Barbados Dollar' },
    { value: 'BDT', text: 'Taka' },
    { value: 'BGN', text: 'Bulgarian Lev' },
    { value: 'BHD', text: 'Bahraini Dinar' },
    { value: 'BIF', text: 'Burundi Franc' },
    { value: 'BMD', text: 'Bermudian Dollar' },
    { value: 'BND', text: 'Brunei Dollar' },
    { value: 'BOB', text: 'Boliviano' },
    { value: 'BOV', text: 'Mvdol' },
    { value: 'BSD', text: 'Bahamian Dollar' },
    { value: 'BTN', text: 'Ngultrum' },
    { value: 'BWP', text: 'Pula' },
    { value: 'BYN', text: 'Belarussian Ruble' },
    { value: 'BYR', text: 'Belarussian Ruble' },
    { value: 'BZD', text: 'Belize Dollar' },
    { value: 'CAD', text: 'Canadian Dollar' },
    { value: 'CDF', text: 'Congolese Franc' },
    { value: 'CHE', text: 'WIR Euro' },
    { value: 'CHF', text: 'Swiss Franc' },
    { value: 'CHW', text: 'WIR Franc' },
    { value: 'CLF', text: 'Unidad de Fomento' },
    { value: 'CLP', text: 'Chilean Peso' },
    { value: 'CNY', text: 'Yuan Renminbi' },
    { value: 'COP', text: 'Colombian Peso' },
    { value: 'COU', text: 'Unidad de Valor Real' },
    { value: 'CRC', text: 'Cost Rican Colon' },
    { value: 'CUC', text: 'Peso Convertible' },
    { value: 'CUP', text: 'Cuban Peso' },
    { value: 'CVE', text: 'Cabo Verde Escudo' },
    { value: 'CZK', text: 'Czech Koruna' },
    { value: 'DJF', text: 'Djibouti Franc' },
    { value: 'DKK', text: 'Danish Krone' },
    { value: 'DOP', text: 'Dominican Peso' },
    { value: 'DZD', text: 'Algerian Dinar' },
    { value: 'EEK', text: 'Estonian Kroon' },
    { value: 'EGP', text: 'Egyptian Pound' },
    { value: 'ERN', text: 'Nakfa' },
    { value: 'ETB', text: 'Ethiopian Birr' },
    { value: 'FJD', text: 'Fiji Dollar' },
    { value: 'FKP', text: 'Falkland Islands Pound' },
    { value: 'GEL', text: 'Lari' },
    { value: 'GGP', text: 'Guernsey Pound' },
    { value: 'GHC', text: 'Ghanaian Cedi' },
    { value: 'GHS', text: 'Ghan Cedi' },
    { value: 'GIP', text: 'Gibraltar Pound' },
    { value: 'GMD', text: 'Dalasi' },
    { value: 'GNF', text: 'Guine Franc' },
    { value: 'GTQ', text: 'Quetzal' },
    { value: 'GYD', text: 'Guyan Dollar' },
    { value: 'HKD', text: 'Hong Kong Dollar' },
    { value: 'HNL', text: 'Lempira' },
    { value: 'HRK', text: 'Croatian Kuna' },
    { value: 'HTG', text: 'Gourde' },
    { value: 'HUF', text: 'Forint' },
    { value: 'IDR', text: 'Rupiah' },
    { value: 'ILS', text: 'New Israeli Sheqel' },
    { value: 'IMP', text: 'Manx Pound' },
    { value: 'INR', text: 'Indian Rupee' },
    { value: 'IQD', text: 'Iraqi Dinar' },
    { value: 'IRR', text: 'Iranian Rial' },
    { value: 'ISK', text: 'Iceland Krona' },
    { value: 'JEP', text: 'Jersey Pound' },
    { value: 'JMD', text: 'Jamaican Dollar' },
    { value: 'JOD', text: 'Jordanian Dinar' },
    { value: 'JPY', text: 'Yen' },
    { value: 'KES', text: 'Kenyan Shilling' },
    { value: 'KGS', text: 'Som' },
    { value: 'KHR', text: 'Riel' },
    { value: 'KMF', text: 'Comoro Franc' },
    { value: 'KPW', text: 'North Korean Won' },
    { value: 'KRW', text: 'Won' },
    { value: 'KWD', text: 'Kuwaiti Dinar' },
    { value: 'KYD', text: 'Cayman Islands Dollar' },
    { value: 'KZT', text: 'Tenge' },
    { value: 'LAK', text: 'Kip' },
    { value: 'LBP', text: 'Lebanese Pound' },
    { value: 'LKR', text: 'Sri Lank Rupee' },
    { value: 'LRD', text: 'Liberian Dollar' },
    { value: 'LSL', text: 'Loti' },
    { value: 'LTL', text: 'Lithuanian Litas' },
    { value: 'LVL', text: 'Latvian Lats' },
    { value: 'LYD', text: 'Libyan Dinar' },
    { value: 'MAD', text: 'Moroccan Dirham' },
    { value: 'MDL', text: 'Moldovan Leu' },
    { value: 'MGA', text: 'Malagasy ariary' },
    { value: 'MKD', text: 'Denar' },
    { value: 'MMK', text: 'Kyat' },
    { value: 'MNT', text: 'Tugrik' },
    { value: 'MOP', text: 'Pataca' },
    { value: 'MRO', text: 'Ouguiya' },
    { value: 'MUR', text: 'Mauritius Rupee' },
    { value: 'MVR', text: 'Rufiyaa' },
    { value: 'MWK', text: 'Kwacha' },
    { value: 'MXN', text: 'Mexican Peso' },
    { value: 'MXV', text: 'Mexican Unidad de Inversion (UDI)' },
    { value: 'MYR', text: 'Malaysian Ringgit' },
    { value: 'MZN', text: 'Mozambique Metical' },
    { value: 'NAD', text: 'Namibi Dollar' },
    { value: 'NGN', text: 'Naira' },
    { value: 'NIO', text: 'Cordob Oro' },
    { value: 'NOK', text: 'Norwegian Krone' },
    { value: 'NPR', text: 'Nepalese Rupee' },
    { value: 'NZD', text: 'New Zealand Dollar' },
    { value: 'OMR', text: 'Rial Omani' },
    { value: 'PAB', text: 'Balboa' },
    { value: 'PEN', text: 'Nuevo Sol' },
    { value: 'PGK', text: 'Kina' },
    { value: 'PHP', text: 'Philippine Peso' },
    { value: 'PKR', text: 'Pakistan Rupee' },
    { value: 'PLN', text: 'Zloty' },
    { value: 'PYG', text: 'Guarani' },
    { value: 'QAR', text: 'Qatari Rial' },
    { value: 'RON', text: 'New Romanian Leu' },
    { value: 'RSD', text: 'Serbian Dinar' },
    { value: 'RUB', text: 'Russian Ruble' },
    { value: 'RUR', text: 'Russian Ruble' },
    { value: 'RWF', text: 'Rwand Franc' },
    { value: 'SAR', text: 'Saudi Riyal' },
    { value: 'SBD', text: 'Solomon Islands Dollar' },
    { value: 'SCR', text: 'Seychelles Rupee' },
    { value: 'SDG', text: 'Sudanese Pound' },
    { value: 'SEK', text: 'Swedish Krona' },
    { value: 'SGD', text: 'Singapore Dollar' },
    { value: 'SHP', text: 'Saint Helen Pound' },
    { value: 'SLL', text: 'Leone' },
    { value: 'SOS', text: 'Somali Shilling' },
    { value: 'SRD', text: 'Surinam Dollar' },
    { value: 'SSP', text: 'South Sudanese Pound' },
    { value: 'STD', text: 'Dobra' },
    { value: 'SVC', text: 'El Salvador Colon' },
    { value: 'SYP', text: 'Syrian Pound' },
    { value: 'SZL', text: 'Lilangeni' },
    { value: 'THB', text: 'Baht' },
    { value: 'TJS', text: 'Somoni' },
    { value: 'TMT', text: 'Turkmenistan New Manat' },
    { value: 'TND', text: 'Tunisian Dinar' },
    { value: 'TOP', text: 'Pa’anga' },
    { value: 'TRL', text: 'Turkish Lira' },
    { value: 'TRY', text: 'Turkish Lira' },
    { value: 'TTD', text: 'Trinidad and Tobago Dollar' },
    { value: 'TWD', text: 'New Taiwan Dollar' },
    { value: 'TZS', text: 'Tanzanian Shilling' },
    { value: 'UAH', text: 'Hryvnia' },
    { value: 'UGX', text: 'Ugand Shilling' },
    { value: 'USN', text: 'US Dollar (Next day)' },
    { value: 'UYI', text: 'Uruguay Peso en Unidades Indexadas (URUIURUI)' },
    { value: 'UYU', text: 'Peso Uruguayo' },
    { value: 'UZS', text: 'Uzbekistan Sum' },
    { value: 'VEF', text: 'Bolivar' },
    { value: 'VES', text: 'Bolivar' },
    { value: 'VND', text: 'Dong' },
    { value: 'VUV', text: 'Vatu' },
    { value: 'WST', text: 'Tala' },
    { value: 'XAF', text: 'CFA Franc BEAC' },
    { value: 'XCD', text: 'East Caribbean Dollar' },
    { value: 'XDR', text: 'SDR (Special Drawing Right)' },
    { value: 'XOF', text: 'CFA Franc BCEAO' },
    { value: 'XPF', text: 'CFP Franc' },
    { value: 'XSU', text: 'Sucre' },
    { value: 'XUA', text: 'ADB Unit of Account' },
    { value: 'YER', text: 'Yemeni Rial' },
    { value: 'ZAR', text: 'Rand' },
    { value: 'ZMW', text: 'Zambian Kwacha' },
    { value: 'ZWD', text: 'Zimbabwe Dollar' },
    { value: 'ZWL', text: 'Zimbabwe Dollar' },
    { value: 'BTC', text: 'BTC' },
    { value: 'ETH', text: 'ETH' },
    { value: 'LTC', text: 'LTC' }
  ] as {value: string, text: string}[],
  TIME_ZONES: [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Algiers',
    'Africa/Bissau',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/El_Aaiun',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Khartoum',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Etc/GMT',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/UTC',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Factory',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis'
  ] as string[],
  ROLLBAR_SESSION_ID: 'rollbarSessionId'
} as const

export default CONSTANTS
