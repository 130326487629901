import { MatchFields } from '@/graphql/fragments/matchFields'
import { StandingFields } from '@/graphql/fragments/standingFields'
import { gql } from '@apollo/client/core'

export const RoundFields = gql`
  fragment RoundFields on RoundV2 {
    roundId
    roundNumber
    isFinalRound
    isPlayoff
    isCertified
    pairingStrategy
    canRollback
    timerId
    matches {
      ...MatchFields
    }
    standings {
      ...StandingFields
    }
  }
  ${MatchFields}
  ${StandingFields}
`
