export const STORE_VIEW_NAMES = {
  MONTH: 'month',
  AGENDA: 'agenda'
}

export const STORE_ROUTE_NAMES = {
  SETTINGS: 'settings',
  TERMS: 'terms',
  EVENTS: 'events',
  EVENT: 'event'
}

export const EVENT_ROUTE_NAMES = {
  REGISTRATION: 'registration',
  DRAFTING: 'drafting',
  DECK_CONSTRUCTION: 'construction',
  PLAYER_LIST_ONLY: 'players',
  PENALTIES: 'penalties',
  MIRROR: 'mirror',
  ROUND: 'round',
  PAIRINGS: 'pairings',
  STANDINGS: 'standings',
  QUEUES: 'queues',
  QUEUE_MANAGER: 'manager',
  MATCHES: 'matches'
}

export const IN_ROUND_ROUTES = [EVENT_ROUTE_NAMES.PAIRINGS, EVENT_ROUTE_NAMES.STANDINGS]
export const PRE_ROUND_ROUTES = [EVENT_ROUTE_NAMES.DRAFTING, EVENT_ROUTE_NAMES.DECK_CONSTRUCTION]
