<template>
  <div class="store-org-group-input">
    <div
      class="store-org-group-input__remove"
      @click="removeGroup"
    >
      <circle-minus-icon
        large
        color="red"
      />
    </div>

    <ElSearchSelect
      v-model="v$.id.$model"
      :validation="v$.id"
      :options="groupOptions"
      field-name="group"
      required
    />
  </div>
</template>

<script setup lang="ts">
import ElSearchSelect from '@/components/common/form/ElSearchSelect.vue'
import CircleMinusIcon from '@/components/common/icons/CircleMinusIcon.vue'
import { OrganizationGroup, OrganizationGroupOption } from '@/types/organizations'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const props = defineProps<{
  group: OrganizationGroup
  groupOptions: Array<OrganizationGroupOption>
}>()

const emit = defineEmits(['removeGroup'])

const rules = {
  id: {
    required
  }
}

const v$ = useVuelidate(rules, props.group)

const removeGroup = () => {
  emit('removeGroup')
}
</script>

<style lang="scss">
.store-org-group-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  >* {
    width: 100%;
  }

  .store-org-group-input__remove {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .icon.icon--circle-minus {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
