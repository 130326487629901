<template>
  <svg
    class="icon icon--circle-exclamation"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM11 17.5V15.5H13V17.5H11ZM11 7.5V13.5H13V7.5H11Z"
      :fill="iconFill"
    />
  </svg>
</template>

<script setup lang="ts">
import { ColorCode, Colors } from '@/types'
import { computed } from 'vue'

const props = defineProps<{
  color: Colors
}>()

const iconFill = computed((): ColorCode => {
  switch (props.color) {
    case 'white':
      return '#FFFFFF' // $white
    case 'purple':
      return '#6F59A5' // $purple-admin
    case Colors.WOTC_PURPLE:
      return '#3A2470' // $wotc-purple
    case 'danger':
      return '#EB5757' // $red
    case 'primary':
      return '#5168e0' // $electric-blue
    default:
      return '#000000'
  }
})
</script>
