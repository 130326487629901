import { Brand, OrganizationGroup } from '@/types/organizations'
import { gql } from '@apollo/client/core'

export interface StoreSearchOptions {
  regions: OrganizationGroup[]
  groups: OrganizationGroup[]
  brands: Brand[]
}

export const GetStoreSearchOptions = gql`
  query getStoreSearchOptions($locale: String!) {
    regions(locale: $locale) {
      id
      name
      isRegion
    }
    groups(locale: $locale) {
      id
      name
    }
    brands(locale: $locale) {
      id
      brandName
      brandId
      isActive
    }
  }
`
