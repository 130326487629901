import { ResultsFields } from '@/graphql/fragments/resultsFields'
import { gql } from '@apollo/client/core'

export const MatchFields = gql`
  fragment MatchFields on MatchV2 {
    matchId
    isBye
    teamIds
    tableNumber
    results {
      ...ResultsFields
    }
  }
  ${ResultsFields}
`
