import { getAppVar } from '@/appsettings'
import CONSTANTS from '@/constants'
import { UnheadPlugin } from '@unhead/vue/vue2'
import BootstrapVue from 'bootstrap-vue'
import { v4 as uuid } from 'uuid'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import vSelect from 'vue-select'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import VueTour from 'vue-tour'
import Vuebar from 'vuebar'
import * as SDK from 'wotc-platform-sdk-js'
import App from './App.vue'
import { i18n } from './lang/config'
import Auth from './plugins/auth'
import Axios from './plugins/axios'
import router from './router'
import { createProvider } from './vue-apollo'

// The Vue FontAwesome plugin recommends individual import of icons
// for tree shaking purposes. See https://github.com/FortAwesome/vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import Rollbar from 'rollbar'

import { initVue } from '@/vm'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { autoScroll } from './directives'
import { ClickOutsideDirective } from './directives/clickOutside'
import { displayName, fullName, nextScreen, pct, teamDisplayName, teamName, teamNameFirstOnly, teamNameLastOnly, teamNameReverse } from './filters'

window.TAB_ID = uuid()

if (!sessionStorage.getItem(CONSTANTS.ROLLBAR_SESSION_ID)) {
  sessionStorage.setItem(CONSTANTS.ROLLBAR_SESSION_ID, uuid())
}
const rollbarSessionId = sessionStorage.getItem(CONSTANTS.ROLLBAR_SESSION_ID)

const appVersion = getAppVar('SGW_VERSION')
const rollbarToken = getAppVar('ROLLBAR_TOKEN')

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
const rollbarInstance = new Rollbar({
  accessToken: rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
  // Additional fields are default scrubbed
  scrubFields: ['email', 'first_name', 'name', 'firstName', 'lastName', 'last_name', 'displayName', 'display_name', 'personaId', 'accountId', 'persona_id', 'account_id'],
  scrubPaths: ['request.query_string'],
  scrubTelemetryInputs: true,
  payload: {
    sessionId: rollbarSessionId,
    environment: import.meta.env.MODE,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: appVersion
      }
    }
  }
})

// Add to Window for SDK
window.$rollbar = rollbarInstance

console.info(`Rollbar added at app version '${appVersion}'`)

window.SDK = SDK

// Configure the platform auth SDK
window.SDK.configure({
  gameID: getAppVar('PLATFORM_GAME_ID'),
  clientID: getAppVar('PLATFORM_CLIENT_ID'),
  clientSecret: getAppVar('PLATFORM_CLIENT_SECRET')
})

window.requestAnimFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60)
    }
})()

// FontAwesome library registration and component
library.add(
  faCheck,
  faSearch,
  faCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faChevronUp,
  faChevronDown,
  faCaretDown,
  faArrowLeft,
  faSortUp,
  faSortDown
)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)

// axios plugin for REST requests
Vue.use(Axios)
Vue.use(Auth)
Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(Vuebar)
Vue.use(VueTour)
Vue.use(UnheadPlugin)

// Datepicker
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

// stop annoying you're in a dev environment message
Vue.config.productionTip = false

Vue.filter('displayName', displayName)
Vue.filter('fullName', fullName)
Vue.filter('teamNameReverse', teamNameReverse)
Vue.filter('teamName', teamName)
Vue.filter('teamDisplayName', teamDisplayName)
Vue.filter('percent', pct)
Vue.filter('nextScreen', nextScreen)
Vue.filter('teamNameFirstOnly', teamNameFirstOnly)
Vue.filter('teamNameLastOnly', teamNameLastOnly)

Vue.directive('click-outside', ClickOutsideDirective)
Vue.directive('auto-scroll', autoScroll)
Vue.component('VSelect', vSelect)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
const { apolloProvider, apolloClient } = createProvider()

const vm = initVue(i18n, router, pinia, apolloProvider, apolloClient, App)
vm.$mount('#app')
