<template>
  <div class="store-phone-group">
    <store-phone-input
      v-for="(phone, idx) in phones"
      :key="phone.id"
      :index="idx"
      :phone="phone"
      :phone-types="phoneTypes"
      @removePhoneNumber="$emit('removePhoneNumber', idx)"
    />
    <div
      class="store-phone-group__actions"
      :class="{ disabled: addDisabled }"
      @click="!addDisabled && $emit('addPhoneNumber')"
    >
      <circle-plus-icon
        purple
        large
      />
      <span class="store-phone-group__action-text">
        {{ $t('action__add-phone-number') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import StorePhoneInput from '@/components/common/form/StorePhoneInput.vue'
import CirclePlusIcon from '@/components/common/icons/CirclePlusIcon.vue'
import CONSTANTS from '@/constants'
import { Option } from '@/types/forms'
import { Phone } from '@/types/wpn-types'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  phones: Array<Phone>
  phoneTypes: Array<Option>
}>()

defineEmits(['addPhoneNumber', 'removePhoneNumber'])

const rules = {
  phones: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(CONSTANTS.MAX_STORE_PHONE_NUMBERS)
  }
}
const v$ = useVuelidate(rules, props)
const addDisabled = computed(() => {
  return v$.value.$invalid || props.phones.length >= CONSTANTS.MAX_STORE_PHONE_NUMBERS
})
</script>

<style lang="scss">
.store-phone-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .store-phone-group__actions {
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .icon.icon--circle-plus {
      width: 30px;
      height: 30px;
    }
    .store-phone-group__action-text {
      margin: 0px 10px;
      font: $fontstyle-link;
      line-height: 16px;
    }
  }
}
</style>
