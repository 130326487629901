import { OptionalDateTime } from '@/types'
import { DateTime } from 'luxon'

export const DATE_YEAR_MONTH = 'yyyy-MM'
export const DATE_YEAR_MONTH_DAY = 'yyyy-MM-dd'
export const DATE_DAYNAME_MONTH_DAY = 'cccc, MM/dd'
export const TIME_HOUR_MINUTE_MERIDIEM = 'hh:mm a'
export const TIME_24HOUR_MINUTE = 'HH:mm'
export const TIME_12HOUR_MINUTE = 'hh:mm'
export const DATETIME_PENALTIES = 'dd/LL/yy hh:mm:ss'

export const asDateTime = (date: OptionalDateTime) => date instanceof DateTime ? date : DateTime.fromISO(date)
