import { GameStateFields } from '@/graphql/fragments'
import { gql } from '@apollo/client/core'

export const GetGameStateAtRound = gql`
  query getGameStateAtRound($eventId: ID!, $round: Int!) {
    gameStateV2AtRound(eventId: $eventId, round: $round) {
      ...GameStateFields
    }
  }
  ${GameStateFields}
`
