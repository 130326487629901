import { SeatFields } from '@/graphql/fragments/seatFields'
import { gql } from '@apollo/client/core'

export const DraftFields = gql`
  fragment DraftFields on DraftV2 {
    timerId
    pods {
      podNumber
      seats {
        ...SeatFields
      }
    }
  }
  ${SeatFields}
`
