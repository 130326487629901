import { gql } from '@apollo/client/core'

export const RegistrationEventFields = gql`
  fragment RegistrationEventFields on Event {
    id
    status
    title
    eventFormat(locale: $locale) {
      id
      name
      color
      requiresSetSelection
      includesDraft
      includesDeckbuilding
      wizardsOnly
    }
    cardSet(locale: $locale) {
      id
      name
    }
    rulesEnforcementLevel
    pairingType
    capacity
    numberOfPlayers
    shortCode
    startingTableNumber
    hasTop8
    isAdHoc
    isOnline
    requiredTeamSize
    eventTemplateId
  }
`
