import { gql } from '@apollo/client/core'

export const GetEventFieldOptions = gql`
  query getEventFieldOptions($locale: String!) {
    eventFormats(locale: $locale) {
      id
      name
      requiresSetSelection
      includesDraft
      includesDeckbuilding
      wizardsOnly
      color
      order
      isActive
      supportedBrands {
        brandId
      }
    }
    cardSets(locale: $locale) {
      id
      name
      releaseDate
    }
  }
`
