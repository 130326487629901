import { RegistrationFields } from '@/graphql/fragments/registrationFields'
import { gql } from '@apollo/client/core'

export const CompanionLobbyFields = gql`
  fragment CompanionLobbyFields on Event {
    id
    registeredPlayers {
      ...RegistrationFields
    }
    interestedPlayers {
      personaId
      displayName
      firstName
      lastName
      emailAddress
    }
  }
  ${RegistrationFields}
`
