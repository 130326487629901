import { gql } from '@apollo/client/core'

export const IncidentFields = gql`
  fragment IncidentFields on Incident {
    id
    ticketId
    offender {
      personaId
      firstName
      lastName
      displayName
    }
    infraction {
      id
      name
      category {
        id
        name
      }
      defaultPenalty {
        id
        name
      }
    }
    penalty {
      id
      name
    }
    roundNumber
    comment
    reportedAt
  }
`
