import { gql } from '@apollo/client/core'

export const GetAuditLog = gql`
  query getAuditLog($id: ID!, $page: Int, $pageSize: Int) {
    organization(id: $id) {
      id
      auditLog(input: { page: $page, pageSize: $pageSize }) {
        page
        pageSize
        totalResults
        results {
          ts
          changedBy {
            firstName
            lastName
            personaId
            displayName
          }
          changes {
            tableId
            op
            tableName
            diff
          }
        }
      }
    }
  }
`
