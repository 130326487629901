import { gql } from '@apollo/client/core'

export const GetTemplateUsages = gql`
  query getTemplateUsages($id: ID!) {
    organization(id: $id) {
      id
      templateUsages {
        templateId
        count
      }
    }
  }
`
