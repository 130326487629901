import { gql } from '@apollo/client/core'

export const GetStoreOptions = gql`
  query getStoreOptions($locale: String!) {
    languages(locale: $locale) {
      id
      name
      code
    }
    regions(locale: $locale) {
      id
      name
    }
    groups(locale: $locale) {
      id
      name
    }
    brands(locale: $locale) {
      brandName
      brandId
      isActive
    }
  }
`
