import { getAppVar } from '@/appsettings'
import { scrollToTop } from '@/util'
import Vue from 'vue'
import Router from 'vue-router'
import guards from './guards'
import routes from './routes'

Vue.use(Router)

// We were getting multiple NavigationDuplicated errors showing up in the console
// This helps resolve those that were not real issues.
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const router = new Router({
  base: getAppVar('BASE_URL'),
  mode: 'history',
  routes
})

router.beforeEach(guards.loginCheck)
router.beforeEach(guards.termsCheck)
router.afterEach(scrollToTop)

export default router
