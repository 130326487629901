const Landing = () => import('@/components/landing/Landing.vue')
const Cookies = () => import('@/components/cookies/Cookies.vue')
export default [
  {
    path: '/',
    alias: '/index.html',
    name: 'landing',
    component: Landing,
    props: true,
    meta: {
      skipAuth: true,
      skipTerms: true
    }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies,
    props: true,
    meta: {
      skipAuth: true,
      skipTerms: true
    }
  }
]
