const WpnList = () => import('@/components/wpn/WpnList.vue')
const WpnSearch = () => import('@/components/wpn/WpnSearch.vue')
const WpnUserSearch = () => import('@/components/wpn/WpnUserSearch.vue')
const WpnHome = () => import('@/components/wpn/WpnHome.vue')
const WpnStoreSelector = () => import('@/components/wpn/WpnStoreSelector.vue')
const WpnManagement = () => import('@/components/wpn/WpnManagement.vue')
const WpnCreateStore = () => import('@/components/wpn/WpnCreateStore.vue')
const WpnEditStore = () => import('@/components/wpn/WpnEditStore.vue')
export default [
  {
    path: '/wpn',
    component: WpnManagement,
    props: true,
    meta: {
      skipTerms: true
    },
    children: [
      {
        path: '',
        name: 'wpnHome',
        component: WpnHome
      },
      {
        path: 'admins',
        name: 'wpnAdmin',
        component: WpnList
      },
      {
        path: 'search',
        name: 'wpnSearch',
        component: WpnSearch
      },
      {
        path: 'user-search',
        name: 'wpnUserSearch',
        component: WpnUserSearch
      },
      {
        path: 'stores',
        name: 'wpnStores',
        component: WpnStoreSelector
      },
      {
        path: 'create-store',
        name: 'wpnCreateStore',
        component: WpnCreateStore
      },
      {
        path: 'edit-store/:storeId/',
        name: 'wpnEditStore',
        props: true,
        component: WpnEditStore
      }
    ]
  }
]
