import { gql } from '@apollo/client/core'

export const RoleFields = gql`
  fragment RoleFields on Organization {
    roles {
      roleName
      user {
        personaId
        firstName
        lastName
        displayName
        emailAddress
      }
    }
  }
`
