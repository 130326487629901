import { ID } from '.'

export enum TimerState {
  HALTED = 'HALTED',
  RUNNING = 'RUNNING',
  DELETED = 'DELETED'
}

export interface Timer {
  id: ID
  state: TimerState
  durationMs: number
  durationStartTime: string
  serverTime: string
}

export interface CreateTimerInput {
  durationMs: number
  state: TimerState
}

export interface SetTimerInput {
  id: ID
  durationMs: number
  state: TimerState
}
