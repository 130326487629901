import { gql } from '@apollo/client/core'

export const GetAdmins = gql`
  query getAdmins {
    admins {
      personaId
      displayName
      emailAddress
      firstName
      lastName
    }
  }
`
