import { gql } from '@apollo/client/core'

export const EventFields = gql`
  fragment EventFields on Event {
    id
    status
    title
    eventFormat(locale: $locale) {
      id
      name
      color
      requiresSetSelection
      includesDraft
      includesDeckbuilding
      wizardsOnly
    }
    cardSet(locale: $locale) {
      id
      name
    }
    rulesEnforcementLevel
    entryFee {
      amount
      currency
    }
    venue {
      id
      name
      latitude
      longitude
      address
      streetAddress
      city
      state
      country
      postalCode
      timeZone
      phoneNumber
      emailAddress
    }
    pairingType
    capacity
    numberOfPlayers
    historicalNumPlayers
    description
    scheduledStartTime
    estimatedEndTime
    actualStartTime
    actualEndTime
    latitude
    longitude
    address
    timeZone
    phoneNumber
    emailAddress
    shortCode
    startingTableNumber
    hasTop8
    isAdHoc
    isOnline
    groupId
    requiredTeamSize
    eventTemplateId
  }
`
