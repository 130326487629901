<template>
  <div class="store-org-group-list">
    <store-org-group-input
      v-for="(group, idx) in groups"
      :key="group.id"
      :group="group"
      :group-options="availableOptions"
      @removeGroup="$emit('removeGroup', idx)"
    />
    <div
      class="store-org-group-list__actions"
      :class="{ disabled: addDisabled, invalid }"
      @click="!addDisabled && $emit('addGroup')"
    >
      <CircleExclamation
        v-if="invalid"
        :color="Colors.DANGER"
      />
      <CirclePlusIcon
        v-else
        purple
        large
      />
      <span class="store-org-group-list__action-text">
        {{ $t('action__add-group') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import StoreOrgGroupInput from '@/components/common/form/StoreOrgGroupInput.vue'
import CircleExclamation from '@/components/common/icons/CircleExclamation.vue'
import CirclePlusIcon from '@/components/common/icons/CirclePlusIcon.vue'
import CONSTANTS from '@/constants'
import { Colors } from '@/types'
import { OrganizationGroup, OrganizationGroupOption } from '@/types/organizations'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { computed } from 'vue'

const props = defineProps<{
  groups: Array<OrganizationGroup>
  groupOptions: Array<OrganizationGroupOption>
}>()

defineEmits(['addGroup', 'removeGroup'])

const rules = {
  groups: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(CONSTANTS.MAX_STORE_GROUPS)
  }
}
const v$ = useVuelidate(rules, props)
const addDisabled = computed(() => {
  return (v$.value.$invalid && props.groups.length !== 0) || props.groups.length >= CONSTANTS.MAX_STORE_GROUPS
})

const invalid = computed(() => {
  return v$.value.$invalid && v$.value.$dirty && props.groups.length === 0
})

const availableOptions = computed(() => {
  const mapped = props.groups.map(grp => grp.id)
  const options = props.groupOptions.map(grp => {
    return mapped.indexOf(grp.value) > -1
      ? {
          ...grp,
          disabled: true
        }
      : grp
  })

  return options
})
</script>

<style lang="scss">
.store-org-group-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .store-org-group-list__actions {
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &.invalid {
      color: $red;
      border: 1px solid $red;
      border-radius: 4px;

      .icon.icon--circle-plus circle,
      rect,
      path {
        stroke: $red;
      }
    }

    .icon.icon--circle-exclamation,
    .icon.icon--circle-plus {
      width: 30px;
      height: 30px;
    }
    .store-org-group-list__action-text {
      margin: 0px 10px;
      font: $fontstyle-link;
      line-height: 16px;
    }
  }
}
</style>
