<template>
  <div
    v-if="isEditing"
    class="store-owner-input__store-owner store-owner-input__saved-edit"
    :class="{ 'store-owner-input__store-owner-missing': !hasSavedOwner }"
  >
    {{ hasSavedOwner ?
      $t('label__saved-store-owner', {
        firstName: savedOwner.firstName,
        lastName: savedOwner.lastName,
        displayName: savedOwner.displayName
      }) : $t('error__missing-store-owner')
    }}
  </div>
  <div
    v-else
    class="store-owner-input__store-owner"
  >
    <b-form-group
      id="el-input__storeOwner-group"
      label-for="el-input__storeOwner"
      class="white-bg store-owner-input__group"
    >
      <template #label>
        {{ $t('label__store-owner') }} * ({{ $t('label__required') }})
      </template>
      <b-input-group>
        <b-form-input
          id="el-input__storeOwner"
          ref="storeOwnerInput"
          :value="modelValue"
          type="text"
          name="el-input__storeOwner"
          :placeholder="$t('hint__store-owner')"
          :disabled="hasSavedOwner"
          @keyup.enter="findOwner"
          @input="save"
        />
        <b-input-group-append>
          <b-button
            class="button-purple"
            :disabled="v$.modelValue.$invalid || hasSavedOwner"
            @click="findOwner"
          >
            {{ $t('action__enter') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div
      v-if="message"
      class="store-owner-input__form-state"
    >
      <div
        class="store-owner-input__form-state-inner"
        :class="'store-owner-input__form-state-' + messageType"
        v-html="message"
      />
    </div>
    <div
      v-if="hasSavedOwner"
      class="store-owner-input__saved"
    >
      {{
        $t('label__saved-store-owner', {
          firstName: savedOwner.firstName,
          lastName: savedOwner.lastName,
          displayName: savedOwner.displayName
        })
      }}
      <b-button
        class="store-owner-input__action store-owner-input__action--delete"
        variant="link"
        @click="$emit('removeStoreOwner')"
      >
        <delete-icon />
      </b-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import DeleteIcon from '@/components/common/icons/DeleteIcon.vue'
import { FindUser } from '@/graphql/queries'
import { useWorkingStore } from '@/stores/working'
import { User } from '@/types/users'
import { getVueInstance } from '@/vm'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n-bridge'

const props = defineProps<{
  modelValue: string
  storeOwner?: User
  isEditing: boolean
}>()

const emit = defineEmits(['update:modelValue', 'saveOwner', 'removeStoreOwner'])

const workingStore = useWorkingStore()

// When editing we don't need to validate the input since it does not exist
const rules = props.isEditing
  ? { modelValue: {} }
  : {
      modelValue: {
        email,
        required
      }
    }
const v$ = useVuelidate(rules, props)
const { t } = useI18n()

const message = ref('')
const messageType = ref('success')
const messageTimeout = ref<ReturnType<typeof setTimeout> | null>(null)
const storeOwnerInput = ref<HTMLInputElement | null>(null)

const savedOwner = computed(() => props.storeOwner ?? { personaId: '', firstName: '', lastName: '', displayName: '' })
const hasSavedOwner = computed(() => {
  return Boolean(props.storeOwner && props.storeOwner.personaId)
})

function save (val: string) {
  if (!storeOwnerInput.value) {
    return
  }
  const start = storeOwnerInput.value.selectionStart
  emit('update:modelValue', val.toLowerCase())
  nextTick(() => {
    storeOwnerInput.value?.setSelectionRange(start, start)
  })
}

function findOwner () {
  if (workingStore.isWorking) {
    return
  }

  // Notify globally that we are performing a request
  workingStore.startWork()

  setMessage({
    message: t('label__looking-for-store-owner'),
    messageType: 'action'
  })

  if (v$.value.$invalid) {
    setMessage({
      messageType: 'error',
      message: t('error__invalid-store-owner')
    })
    return
  }

  getVueInstance().$apollo.query({
    query: FindUser,
    variables: { emailAddress: props.modelValue.toLowerCase() }
  }).then(({ data: { user } }) => {
    emit('saveOwner', user)
    setMessage({
      messageTimeout: 1000,
      message: t('label__found-store-owner', {
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.displayName
      })
    })
  }).catch((e: Error) => {
    console.error('Failed to find email for StoreOwner:', e)
    setMessage({ messageType: 'error', message: t('error__invalid-store-owner') })
  }).finally(() => {
    workingStore.stopWork()
  })
}

function setMessage ({ messageTimeout: newTimeout = 3000, messageType: newType = 'success', message: newMessage = '' }) {
  if (messageTimeout.value) {
    clearTimeout(messageTimeout.value)
    messageTimeout.value = null
  }

  message.value = newMessage
  messageType.value = newType
  messageTimeout.value = setTimeout(() => {
    message.value = ''
    messageType.value = 'success'
    messageTimeout.value = null
  }, newTimeout)
}
</script>

<style lang="scss">
.store-owner-input__store-owner {
  .store-owner-input__group {
    .input-group {
      border-radius: 0px 4px 4px 4px;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.25);

      input {
        color: $purple-admin;
        border: none;
        height: 40px;
        background: #ffffff;
        border-radius: 0px 4px 4px 4px;
        &::placeholder {
          color: rgba($purple-admin, 0.3);
        }
      }
    }
    label {
      text-transform: none;
      font: $fontstyle-label-3;
      padding-bottom: 10px;
      font-weight: $fontweight-bold;
    }
  }
  &.store-owner-input__saved-edit {
    font-family: $font-helvetica;

    &.store-owner-input__store-owner-missing {
      color: $red;
    }
  }

  .store-owner-input__saved {
    padding-top: 20px;
    font: $fontstyle-link;
    margin: 0 10px;
    line-height: 16px;
    .store-owner-input__action {
      position: relative;
      bottom: 4px;
      height: 20px;
      &.btn.btn-link {
        padding: 0 10px;
      }
      &--delete {
        .icon {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
  .store-owner-input__form-state {
    position: relative;
  }
  .store-owner-input__form-state-inner {
    position: absolute;
    z-index: 20;
    font: $fontstyle-paragraph;
    line-height: 16px;
    border-radius: 0px 0px 4px 4px;
    padding: 12px 10px;
    width: 100%;

    @include md {
      width: 100%;
      left: 0;
    }

    strong {
      font-weight: $fontweight-bold;
    }
  }
  .store-owner-input__form-state-success {
    background-color: $green-3;
    color: white;
  }
  .store-owner-input__form-state-guest {
    background-color: $purple-2;
    color: white;
  }
  .store-owner-input__form-state-action {
    background-color: $blue-3;
    color: white;
  }
  .store-owner-input__form-state-error {
    background-color: $red;
    color: white;
  }
  .store-owner-input__form-state-warning {
    background-color: $orange;
    color: white;
  }
}
</style>
